import $ from 'jquery'
import Accesibilidad from '../components/Accesibilidad'
import GaleriaHeader from '../components/GaleriaHeader'
import Alerta from '../components/Alerta';
import Alerta_ from '../assets/js/Alerta';
import { Link, withRouter } from "react-router-dom";


const isesion = () => {
    var usr = "";
    var psw = "";
    
    usr=$("#usr").val();
    psw=$("#psw").val();

    

    var url = "https://sistemaintegral.conavi.gob.mx:81/api/sesiondac/" + usr + "/" + psw;
    $.getJSON(url, function (data) {
        $.each(data, function (key, val) {
            
            if (val.nombre == "INEXISTENTE") {
                Alerta_("<p style='text-align: justify'><strong>El nombre de usuario o la contraseña son incorrectos.</strong> Comprueba que no esté activada la tecla Bloq Mayús y vuelve a introducir el nombre de usuario y contraseña.</p>", "alertaOp alert alert-warning");
            } else {
              
                
                localStorage.setItem('credenciales_dac', JSON.stringify(data));
                                
                window.location.href = "/dac_2022_/#/Panel"

            }
        });
    });
}

function Sesion(props) {

    return (
        <div className="Inicio">
            <header>


                <Accesibilidad />
                <Alerta />
                <GaleriaHeader />


                <div id="cont" class="container">

                    <div class="login">

                        <div class="login-in">
                            
                        <p><i class="icon-cnv gold-ic login-ic"></i></p>
                        <p class="login-vn red-ic">Bienvenido</p>
                            <small>Usuario:</small>
                            <input id="usr" type="text" class="form-control"></input>
                            <small>Contaseña:</small>
                            <input id="psw" type="password" class="form-control"></input>
                            <button class="btn btn-primary"
                            onClick={isesion}
                            >Iniciar sesión</button>
                        </div>
                    </div>


                </div>

            </header>
        </div>
    );
}

export default Sesion;