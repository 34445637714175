import React from "react";
import '../assets/css/slideshow.css';
import '../assets/js/slideshow.js';

export default function GaleriaHeader() {
    return (
        <p>
            <div>

                <div id="slideshow-container" class="slideshow-container" >

                    <div class="mySlides slider gal_1 gal-strech">
                    </div>
                    <div> <div class="dots-position">
                        <span class="dot"> </span>

                    </div>
                    </div >
                </div>
            </div>
        </p>
    );
}