import React from "react";
import { HashRouter, Link, Redirect, Route, Switch } from "react-router-dom";
import ScrollToTop from './components/scrollToTop'
import Inicio from "./pages/inicio.jsx"
import Queja from "./pages/queja.jsx"
import Informacion from "./pages/informacion.jsx"
import Vivienda from "./pages/vivienda.jsx"
import Historico from "./pages/historico.jsx"
import Frecuentes from "./pages/frecuentes.jsx"

import Sesion  from "./pages/sesion.jsx"
import Panel  from "./pages/panel.jsx"
import Queja_ from "./pages/queja_p.jsx"
import Informacion_ from "./pages/informacion_p.jsx"
import Vivienda_ from "./pages/vivienda_p.jsx"
import Colectiva_ from "./pages/colectiva_p.jsx"
import Solicitud from "./pages/solicitud.jsx"
import Informes from "./pages/Informes.jsx"






function App() {
	return (
		<div className="App">

			<HashRouter >
				<Switch>
					<ScrollToTop>
						<Route exact path='/' render={({ location }) => <Redirect to={'/Inicio'} />} />
						<Route path="/Inicio" component={Inicio} />
						<Route path="/Queja" component={Queja} />
						<Route path="/Informacion" component={Informacion} />
						<Route path="/Vivienda" component={Vivienda} />
						<Route path="/Historico" component={Historico} />
						<Route path="/Frecuentes" component={Frecuentes} />

						<Route path="/Admin" component={Sesion} />
						<Route path="/Panel" component={Panel} />
						<Route path="/Vivienda_p" component={Vivienda_} />
						<Route path="/Informacion_p" component={Informacion_} />
						<Route path="/Queja_p" component={Queja_} />
						<Route path="/Colectiva_p" component={Colectiva_} />
						<Route path="/Solicitud" component={Solicitud} />
						<Route path="/Informes" component={Informes} />
						
						
					</ScrollToTop>
				</Switch>
			</HashRouter>


		</div>

	);
}


export default App;
