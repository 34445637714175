import $, { each } from 'jquery';
import '../../assets/css/scroll.css';
import '../../assets/css/modal.css';
import '../../assets/css/content.css';

import ine from '../../assets/img/INE_V.jpg';
export default function Modaline() {


	return (
		<div id='content-i' class='modal-content' style={{ display: "none", overflow: "hidden" }}>

			<div class='modal-header'>
				<h4 id='titulo-modal' style={{ fontWeight: 300 }}>¿Dónde encuentro la CURP en mi IFE/INE?</h4>
			</div>
			<div class='modal-body' style={{ height: 450, overflowX: "auto", overflowY: "auto" }}>

				<div class="row">
				<img style={{width: "-webkit-fill-available"}} class="img-responsive" src={ine}/>
				</div>

				
				<button id="btnCerrar-i" class='col-md-3 col-xs-12  btn-default bottom-buffer-x15'>Cerrar</button>


			</div>
		</div>
	);
}