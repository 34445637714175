import $, { each } from 'jquery';

import { Link } from "react-router-dom";
import Accesibilidad from '../components/Accesibilidad'
import GaleriaHeader from '../components/GaleriaHeader'
import Alerta from '../components/Alerta';
import Alerta_ from '../assets/js/Alerta';
import Vivienda from './vivienda_p';
import React, { useState, useEffect } from 'react';
import '../assets/css/content.css';
import '../assets/css/navbar.css';



const load = () => {
    jqueryF();

    isLoged();

    var user = JSON.parse(localStorage.getItem('credenciales_dac'));
    $(".ic-modulos").remove();
    $.each(user, function (key, val) {
        $("#usr").text(val.nombre);
    });



    var Modulos = [];

    var user = JSON.parse(localStorage.getItem('credenciales_dac'));

    $.each(user, function (key, val) {
        Modulos = val.modulos.split(",");

    });

    for (var i = 0; i < Modulos.length; i++) {

        var Md = [];
        Md = Modulos[i].split("|")


        $("#modulos").append('<a href="' + Md[1] + '"><p style="font-size:14px;text-align:left; color:gray" class="ic-modulos enlace">' + Md[0] + '<p></a>');


    }
    solicitudes();
    $(".loading").addClass("hidden");


}

function isLoged() {
    const isLogged = localStorage.getItem('credenciales_dac');
    if (!isLogged) {
        out();
    } else {

    }
}
function fun() {
    $('body').on('click', 'p', function (e) {
        if (e.target.className === "ic-solicitudes enlace") {
            localStorage.removeItem("id_solicitud");
            localStorage.removeItem("tipo_solicitud");

            localStorage.setItem("id_solicitud", $("#" + e.target.id).attr("num_solicitud"));
            localStorage.setItem("tipo_solicitud", $("#" + e.target.id).attr("tipo_solicitud"));

            window.location.href = "/dac_2022_/#/Solicitud";
        }
    });
}
function solicitudes() {

    var url = "https://sistemaintegral.conavi.gob.mx:81/api/solicitudesDAC";
    $.getJSON(url, function (data) {
        $.each(data, function (key, val) {
            var fecha = val.created_at;

            if (val.formulario === "SOLICITUD COLECTIVA") {
                $("#solicitudes").append(
                    '<p id="sol_' + val.id + '" style="font-size:14px; color:gray" num_solicitud="' + val.id + '" tipo_solicitud="' + val.formulario + '" class="ic-solicitudes">TIENES UNA NUEVA <strong>' + val.formulario + '</strong></p><p></p>' +
                    '<div style="display: flex;justify-content: flex-end; float:left"><span style="font-size:10px; color:gray"><i class="fas fa-clock"></i> Recibida el <Strong>' + fecha.replace('T', ' ').replace('.000Z', '') + '</strong></span></div><div style="display: flex;justify-content: flex-end;"><div class="enlace btn-atendida"><span style="font-size:10px; color:gray"><i class="fas fa-check-double"></i> Atendida</span></div></div><hr class="separador_">');
            } else {
                $("#solicitudes").append(
                    '<p id="sol_' + val.id + '"  style="font-size:14px; color:gray" num_solicitud="' + val.id + '" tipo_solicitud="' + val.formulario + '" class="ic-solicitudes enlace">TIENES UNA NUEVA <strong>' + val.formulario + '</strong> PROVENIENTE DEL ESTADO DE <strong>' + val.nombre_estado + '</strong>.</p><p></p>' +
                    '<div style="display: flex;justify-content: flex-end; float:left"><span style="font-size:10px; color:gray"><i class="fas fa-clock"></i> Recibida el <Strong>' + fecha.replace('T', ' ').replace('.000Z', '') + '</strong></span></div><div style="display: flex;justify-content: flex-end;"><div class="enlace btn-atendida"><span style="font-size:10px; color:gray"><i class="fas fa-check-double"></i> Atendida</span></div></div><hr class="separador_">');
            }


        });
    });

}


const out = () => {
    localStorage.clear();
    window.location.href = "/dac_2022_/#/Admin";
}

const historico = () => {

}

function jqueryF() {
    /*document.body.addEventListener('click', function (event) {
        
        if (event.srcElement.className == 'Historico') {
            
            
        };
    });*/
}

export default function Panel(props) {


    useEffect(() => {
        fun();
        load();
        localStorage.removeItem("tipo_solicitud");
        localStorage.removeItem("id_solicitud");
    });



    return (
        <div className="Inicio">
            <header>

                <div class="loading">
                    <i class="gold fa fa-spin fa-bahai loading-icon"> </i>
                </div>


                <Accesibilidad />
                <Alerta />
                <GaleriaHeader />







                <div id="cont" class="container">
                    <p style={{ textAlign: "end" }}><span><i class="fas fa-user-alt gold"></i><strong style={{ paddingLeft: 8, paddingRight: 8 }} id="usr"></strong></span><span onClick={out} class="gold" style={{ cursor: "pointer" }}>/ Salir<i class="fas fa-sign-out-alt"></i></span></p>

                    <ol id="bread" class="breadcrumb">
                        <li><a href=""><i class="icon icon-home"></i></a></li>
                        <li class="active"><Link class="nav-link" to="/Panel">Panel de administrador</Link></li>
                    </ol>

                    <div class="row">
                        <div class="col-md-8 top-buffer-x25">
                            <img class="back-dac"></img>


                        </div>

                        <div class="col-md-4 top-buffer-x25">
                            <div class="modulos-dac ">
                                <p class="modulo-ic"><i class="fas fa-bars"></i> Menú <strong>principal</strong></p>
                                <hr class="separador_"></hr>
                                <p class="bottom-buffer"></p>
                                <div>
                                    <div id="modulos">


                                    </div>

                                </div>

                            </div>

                            <div class="modulos-dac top-buffer-x25">


                                <p class="modulo-ic"><i class="fas fa-envelope-open-text"></i> Nuevas <strong>solicitudes</strong></p>
                                <hr class="separador_"></hr>
                                <p class="bottom-buffer"></p>
                                <div id="solicitudes">


                                </div>

                            </div>
                        </div>
                    </div>

                </div>




                <div class="colofon red-text text-center">
                    <div class="row row-fixed">
                        <div class="col-lg-12 my-auto showcase-text">
                            <p class="lead mb-0 p-center">
                                Av. H. Escuela Naval Militar, Coapa, Presidentes Ejidales 1ª Sección, Ciudad de México. C.P. 04470<br></br>Teléfono: 55.91.38.99.91<br></br>Opción 1<br></br>atencionciudadana@conavi.gob.mx
                            </p>
                        </div>
                    </div>
                </div>

            </header>
        </div>

    );


}
