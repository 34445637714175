import $, { each } from 'jquery';

import { Link } from "react-router-dom";
import Accesibilidad from '../components/Accesibilidad'
import GaleriaHeader from '../components/GaleriaHeader'
import Alerta from '../components/Alerta';
import Alerta_ from '../assets/js/Alerta';
import Vivienda from './vivienda_p';
import React, { useState, useEffect } from 'react';
import '../assets/css/content.css';
import '../assets/css/navbar.css';
import Map from '../components/map.jsx'


const out = () => {
    localStorage.clear();
    window.location.href = "/dac_2022_/#/Admin";
}



function mapa() {
    var url = "https://sistemaintegral.conavi.gob.mx:81/api/mapaSolicitudes";
    $.getJSON(url, function (data) {
        $.each(data, function (key, val) {
            console.log(data)
            $("#" + val.acronimo).removeClass("-fill-");
            $("#" + val.acronimo).addClass("-fillData-");
            $("#" + val.acronimo).attr(val.formulario, val.solicitudes);


        });
    });

}

function isLoged() {
    const isLogged = localStorage.getItem('credenciales_dac');
    if (!isLogged) {
        out();

    } else {
        let user = JSON.parse(localStorage.getItem('credenciales_dac'));
        
        $.each(user, function (key, val) {
            $("#usr").text(val.nombre);
        });

        mapa();
    }
}


export default function Informes(props) {
    useEffect(() => {
       isLoged();
    });



    return (
        <div className="Inicio">
            <header>

                <div class="loading">
                    <i class="gold fa fa-spin fa-bahai loading-icon"> </i>
                </div>


                <Accesibilidad />
                <Alerta />
                <GaleriaHeader />


                <div id="cont" class="container">
                    <p style={{ textAlign: "end" }}><span><i class="fas fa-user-alt gold"></i><strong style={{ paddingLeft: 8, paddingRight: 8 }} id="usr"></strong></span><span onClick={out} class="gold" style={{ cursor: "pointer" }}>/ Salir<i class="fas fa-sign-out-alt"></i></span></p>

                    <ol id="bread" class="breadcrumb">
                        <li><a href=""><i class="icon icon-home"></i></a></li>
                        <li class=""><Link class="nav-link" to="/Panel">Panel de administrador</Link></li>
                        <li class="active"><Link class="nav-link">Informes</Link></li>
                    </ol>

                    <div id="icons" class="row text-center bottom-buffer">

                        <div class="col-md-3 col-xs-6 icon-badge-container ">
                            <i class="fas fa-home gold" style={{ fontSize: 40 }}></i>
                            <div id="vivienda" class="icon-badge">0</div>
                        </div>
                        <div class="col-md-3 col-xs-6 icon-badge-container ">
                            <i class="fas fa-bullhorn gold" style={{ fontSize: 40 }}></i>
                            <div id="queja" class="icon-badge">0</div>
                        </div>
                        <div class="col-md-3 col-xs-6 icon-badge-container ">
                            <i class="fas fa-info-circle gold" style={{ fontSize: 40 }}></i>
                            <div id="informacion" class="icon-badge">0</div>
                        </div>
                        <div class="col-md-3 col-xs-6 icon-badge-container ">
                            <i class="fad fa-user-plus gold" style={{ fontSize: 40 }}></i>
                            <div class="icon-badge">0</div>
                        </div>

                    </div>
                    <div class="row">
                        <Map></Map>
                    </div>

                    <div class="row text-center top-buffer">

                        <div class="col-md-3">
                            <div class="card div-middle" style={{ padding: 10 }}>
                                <p class="p-center gold-enlace-t">Solicitudes de información</p>
                                <a href='https://sistemaintegral.conavi.gob.mx:81/api/get_queja_info/SOLICITUD DE INFORMACIÓN' target="_blank"><i class="fas fa-file-excel gold-enlace"></i></a>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="card div-middle" style={{ padding: 10 }}>
                                <p class="p-center gold-enlace-t">Quejas y denuncias</p>
                                <a href='https://sistemaintegral.conavi.gob.mx:81/api/get_queja_info/QUEJA O DENUNCIA' target="_blank"><i class="fas fa-file-excel gold-enlace"></i></a>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="card div-middle" style={{ padding: 10 }}>
                                <p class="p-center gold-enlace-t">Solicitudes de vivienda</p>
                                <a href='https://sistemaintegral.conavi.gob.mx:81/api/get_vivienda' target="_blank"><i class="fas fa-file-excel gold-enlace"></i></a>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <div class="card div-middle" style={{ padding: 10 }}>
                                <p class="p-center gold-enlace-t">Solicitudes colectivas</p>
                                <a href='https://sistemaintegral.conavi.gob.mx:81/api/get_colectiva' target="_blank"><i class="fas fa-file-excel gold-enlace"></i></a>
                            </div>
                        </div>

                        

                    </div>
                </div>




                <div class="colofon red-text text-center">
                    <div class="row row-fixed">
                        <div class="col-lg-12 my-auto showcase-text">
                            <p class="lead mb-0 p-center">
                                Av. H. Escuela Naval Militar, Coapa, Presidentes Ejidales 1ª Sección, Ciudad de México. C.P. 04470<br></br>Teléfono: 55.91.38.99.91<br></br>Opción 1<br></br>atencionciudadana@conavi.gob.mx
                            </p>
                        </div>
                    </div>
                </div>

            </header >
        </div >

    );


}
