import $ from 'jquery';
import Accesibilidad from '../components/Accesibilidad'
import GaleriaHeader from '../components/GaleriaHeader'
import Axios from 'axios';
import Alerta from '../components/Alerta';
import Alerta_ from '../assets/js/Alerta.js';
import '../assets/js/jquery.serializeToJSON.js';
import Video from '../assets/vid/VIDEO.mp4'
import Modalcurp from '../components/modal/Modalcurp'
import Modaline from '../components/modal/Modaline'
import '../assets/css/scroll.css';
import '../assets/css/navbar.css';
import '../assets/css/content.css';
import '../assets/css/emergente.css';
import '../assets/css/Alerta.css';





import { useState } from 'react';
import { Link, withRouter } from "react-router-dom";

let estado;
const p1 = '<strong>NO, </strong>el gobierno federal a través de la Conavi, otorga subsidios a las personas beneficiarias, que son montos de apoyo económico no recuperables.';
const p2 = '<strong>NO, todos los trámites son gratuitos</strong> y se realizan de manera individual y directa. Puedes comunicarte con nosotros al 55 9138 9991, opción 1 o escribirnos a atencionciudadana@conavi.gob.mx, donde con gusto te orientaremos.';
const p3 = "La Comisión Nacional de Vivienda tiene como objetivo prioritario disminuir la carencia de una vivienda adecuada de la población en situación de vulnerabilidad por condiciones sociodemográficas, por riesgo o por precariedad de su vivienda. Para lograrlo, ejecuta el <strong>Programa Nacional de Reconstrucción</strong>, Componente de Vivienda y el <strong>Programa de Vivienda Social.</strong>";
const p4 = "<strong>Programa Nacional de Reconstrucción.</strong> - Las acciones y proyectos del Programa están dirigidos a los municipios afectados por los sismos ocurridos los días 07 y 19 de septiembre de 2017 y el 16 de febrero de 2018, en la región centro-sur del país, en beneficio de la población y comunidades que aún no han sido atendidos o lo fueron parcialmente; priorizando la atención a quienes habiten en zonas de alta y muy alta marginación, con población mayoritariamente indígena o con altos índices de violencia, considerando las localidades con mayor concentración de daños materiales, la proporcionalidad de la afectación por el número de inmuebles en la localidad, y el mayor daño en la infraestructura y las viviendas.";
const p5 = "Tiene como objetivo disminuir la carencia de una vivienda adecuada de la <strong>población en situación de vulnerabilidad por condiciones sociodemográficas, por riesgo o por precariedad de su vivienda, priorizando a la población que se encuentre en alguna de las siguientes situaciones.</strong><br/><br/>" +
    "<p><span class='text-dot'> </span>Personas que habitan en zonas de población mayoritariamente indígena.</p>" +
    "<p><span class='text-dot'> </span>Mujeres jefas de hogar.</p>" +
    "<p><span class='text-dot'> </span>Población que haya sido afectada por fenómenos naturales perturbadores.</p>" +
    "<p><span class='text-dot'> </span>Población asentada en situación de riesgo.</p>" +
    "<p><span class='text-dot'> </span>Población que habita en zonas urbanas o rurales con alto o muy alto índice de marginación y/o en zonas con altos índices de violencia e inseguridad.</p>" +
    "<p><span class='text-dot'> </span>Grupos sociales en situación de vulnerabilidad por riesgo, por ingreso o por condiciones sociodemográficas.</p>" +
    "<p><span class='text-dot'> </span>Migrantes mexicanos en el extranjero y de retorno.</p>";
const p6 = "La solicitud se registrará, sin embargo, el subsidio para vivienda se asignará a aquellas personas que lo soliciten, cumplan con los requisitos y criterios de elegibilidad señalados en las Reglas de Operación, de acuerdo con la suficiencia presupuestal y cobertura de intervención territorial programada que corresponda para el ejercicio fiscal.";
const p7 = "Puedes comunicarte con nosotros al 55 9138 9991, opción 1 o escribirnos a atencionciudadana@conavi.gob.mx, donde con gusto te orientaremos.";
const p8 = "<strong>No</strong>, la Conavi no tiene oficinas en las entidades federativas, sin embargo, cuenta con un equipo en territorio que da seguimiento a los trabajos de obra que se realizan.<br/><br/>Si tienes dudas, contáctanos al 55 9138 9991, opción 1 o escríbenos al correo atencionciudadana@conavi.gob.mx, donde con gusto te orientaremos.";
const p9 = "<strong>Los apoyos que otorga la Comisión a través del Programa, se basan en dos esquemas de operación:<br/><br/>a) Subsidio Conavi 100%</strong></br>Son subsidios otorgados directamente a la persona beneficiaria, no asociados a crédito y a subsidio de otra entidad, y serán utilizados para cubrir necesidades de la población prioritaria.<br/><br/><strong>b) Cofinanciamiento</strong><br/>El esquema de Cofinanciamiento se basa en la combinación de aportaciones de distintas fuentes de recursos para la correcta ejecución de la intervención: Subsidio Conavi, crédito de Entidad Ejecutora, ahorro previo de la persona beneficiaria, subsidio local (Estatal o Municipal), aportación del Organismo Estatal o Municipal de Vivienda, y aportaciones de cualquier ente público, social y privado (que podrán ser aportaciones económicas, sociales o en especie).";

const asigna_div_informacion = (titulo, subtitulo, mensaje) => {
    $("#titulo").text(titulo);
    $("#subtitulo").text(subtitulo);
    $("#mensaje").html(mensaje);
    $("#div_informacion").css("display", "block");
    scroll("div_informacion");
}

const div_informacion = (e) => {
    if (e != "") {

        switch (e) {
            case "card_info1": asigna_div_informacion(
                $("#" + e).text(), "",
                p1); break;
            case "card_info2": asigna_div_informacion(
                $("#" + e).text(), "",
                p2); break;
            case "card_info3": asigna_div_informacion(
                $("#" + e).text(), "",
                p3); break;
            case "card_info4": asigna_div_informacion(
                $("#" + e).text(), "",
                p4); break;
            case "card_info5": asigna_div_informacion(
                $("#" + e).text(), "",
                p5); break;
            case "card_info6": asigna_div_informacion(
                $("#" + e).text(), "",
                p6); break;
            case "card_info7": asigna_div_informacion(
                $("#" + e).text(), "",
                p7); break;
            case "card_info8": asigna_div_informacion(
                $("#" + e).text(), "",
                p8); break;

            case "card_info9": asigna_div_informacion(
                $("#" + e).text(), "",
                p9); break;
        }
    }

}



const scroll = (id) => {

    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
}








function Frecuentes(props) {

    return (
        <div className="Inicio">
            <header>


                <Accesibilidad />
                <Alerta />
                <GaleriaHeader />


                <div id="cont" class="container">

                    <ol id="breadcrumb" class="breadcrumb">
                        <li><a href=""><i class="icon icon-home"></i></a></li>
                        <li class=""><Link class="nav-link" to="/#/Inicio">Inicio</Link></li>
                        <li class="active"><Link class="nav-link">Preguntas frecuentes</Link></li>
                    </ol>



                    <form id="form_informacion">

                        <div id="params">
                            <input type="hidden" id="form_formulario" name="form.formulario" value="SOLICITUD DE INFORMACIÓN" />
                            <input type="hidden" id="form_usuario" name="form.usuario" value="PORTAL" />
                            <input type="hidden" id="form_clasificacion" name="form.clasificacion" />
                            <input type="hidden" id="form_nombre" name="form.nombre" />
                            <input type="hidden" id="form_apellido1" name="form.apellido1" />
                            <input type="hidden" id="form_apellido2" name="form.apellido2" />

                        </div>

                        <div id="informacion_selector" >
                            <div>
                                <h2>Preguntas frecuentes</h2>
                                <hr class="red"></hr>
                                <div class="col-md-4 bottom-buffer-x15 dropdown-at dropbtn-at"
                                    onClick={(e) => div_informacion(e.target.id)}>
                                    <div id="card_info1" class="card card-center">
                                        <i id="card_info1" style={{ fontSize: 50 }} class="icon-cnv red-ic"></i>
                                        <p id="card_info1" class="card-tittle red">¿La Conavi otorga créditos?</p>


                                    </div>
                                </div>

                                <div class="col-md-4 bottom-buffer-x15 dropdown-at dropbtn-at"
                                    onClick={(e) => div_informacion(e.target.id)}>
                                    <div id="card_info2" class="card card-center">
                                        <i id="card_info2" style={{ fontSize: 50 }} class="fas fa-user-tie red-ic"></i>

                                        <p id="card_info2" class="card-tittle red">¿Necesito un gestor para realizar la solicitud del subsidio?</p>


                                    </div>
                                </div>

                                <div class="col-md-4 bottom-buffer-x15 dropdown-at dropbtn-at"
                                    onClick={(e) => div_informacion(e.target.id)}>
                                    <div id="card_info3" class="card card-center">
                                        <i id="card_info3" style={{ fontSize: 50 }} class="fas fa-list red-ic"></i>

                                        <p id="card_info3" class="card-tittle red">¿Qué programas ejecuta la Conavi?</p>


                                    </div>
                                </div>



                                <div class="col-md-12 bottom-buffer-x15 dropdown-at dropbtn-at"
                                    onClick={(e) => div_informacion(e.target.id)}>
                                    <div id="card_info9" class="card card-center">
                                        <i id="card_info9" style={{ fontSize: 50 }} class="fas fa-tasks red-ic"></i>
                                        <p id="card_info9" class="card-tittle red">¿Cuáles son las características de los apoyos que otorga la Conavi a través del Programa de Vivienda Social?</p>


                                    </div>
                                </div>
                                <div class="col-md-12 bottom-buffer-x15 dropdown-at dropbtn-at"
                                    onClick={(e) => div_informacion(e.target.id)}>
                                    <div id="card_info4" class="card card-center">
                                        <i id="card_info4" style={{ fontSize: 50 }} class="fas fa-tasks red-ic"></i>

                                        <p id="card_info4" class="card-tittle red">¿Cuáles son los criterios de elegibilidad y los requisitos para recibir un subsidio del Programa Nacional de Recostrucción, Componente de Vivienda?</p>


                                    </div>
                                </div>
                                <div class="col-md-6 bottom-buffer-x15 dropdown-at dropbtn-at"
                                    onClick={(e) => div_informacion(e.target.id)}>
                                    <div id="card_info5" class="card card-center">
                                        <i id="card_info5" style={{ fontSize: 50 }} class="fas fa-users red-ic"></i>

                                        <p id="card_info5" class="card-tittle red">¿Quién puede ser atendido a través del Programa de Vivienda Social?</p>


                                    </div>
                                </div>

                                <div class="col-md-6 bottom-buffer-x15 dropdown-at dropbtn-at"
                                    onClick={(e) => div_informacion(e.target.id)}>
                                    <div id="card_info6" class="card card-center">
                                        <i id="card_info6" style={{ fontSize: 50 }} class="fas fa-user-check red-ic"></i>

                                        <p id="card_info6" class="card-tittle red">Si ya presenté mi solicitud en la Conavi, ¿Ya soy persona beneficiaria del Programa de Vivienda Social?</p>


                                    </div>
                                </div>

                                <div class="col-md-6 bottom-buffer-x15 dropdown-at dropbtn-at"
                                    onClick={(e) => div_informacion(e.target.id)}>
                                    <div id="card_info7" class="card card-center">
                                        <i id="card_info7" style={{ fontSize: 50 }} class="fas fa-address-book red-ic"></i>

                                        <p id="card_info7" class="card-tittle red">¿A dónde me puedo comunicar para darle seguimiento a mi solicitud de subsidio?</p>


                                    </div>
                                </div>

                                <div class="col-md-6 bottom-buffer-x15 dropdown-at dropbtn-at"
                                    onClick={(e) => div_informacion(e.target.id)}>
                                    <div id="card_info8" class="card card-center">
                                        <i id="card_info8" style={{ fontSize: 50 }} class="fas fa-building red-ic"></i>

                                        <p id="card_info8" class="card-tittle red">¿Conavi cuenta con oficinas en las entidades federativas?</p>


                                    </div>
                                </div>

                                <div id="div_informacion" class="div_informacion">
                                    <h4 id="titulo" class="text-center div-queja-text"></h4>
                                    <p id="subtitulo" class="text-center div-queja-text"></p>
                                    <div class="separador"></div>
                                    <p id="mensaje" class="div-queja-text"></p>
                                    <div class="area-footer">

                                    </div>
                                </div>
                            </div>


                        </div>

                    </form>
                </div>

                <div class="colofon red-text text-center">
                    <div class="row row-fixed">
                        <div class="col-lg-12 my-auto showcase-text">
                            <p class="lead mb-0 p-center">
                                Avenida H. Escuela Naval Militar, número 669, piso 2 y 3, Colonia Presidentes Ejidales 1ra. Sección, Alcaldía Coyoacán, C.P. 04470, Ciudad de México.
                                <br></br>Teléfono: 55.91.38.99.91<br></br>Opción 1<br></br>atencionciudadana@conavi.gob.mx
                            </p>
                        </div>
                    </div>
                </div>

            </header>
        </div >
    );
}

export default Frecuentes;