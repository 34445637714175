import $ from 'jquery';
import Accesibilidad from '../components/Accesibilidad'
import GaleriaHeader from '../components/GaleriaHeader'
import Axios from 'axios';
import Alerta from '../components/Alerta';
import Alerta_ from '../assets/js/Alerta.js';
import '../assets/js/jquery.serializeToJSON.js';
import Video from '../assets/vid/VIDEO.mp4'
import Modalcurp from '../components/modal/Modalcurp'
import Modaline from '../components/modal/Modaline'
import '../assets/css/scroll.css';
import '../assets/css/navbar.css';
import '../assets/css/content.css';
import '../assets/css/emergente.css';
import '../assets/css/Alerta.css';
import { useEffect, useState } from 'react';

import { Link, withRouter } from "react-router-dom";

const titulo_int_patr = "CONTRA LA INTEGRIDAD Y PATRIMONIO DE LA PERSONA BENEFICIARIA."
const titulo_obra = "RELACIONADAS CON EL SEGUIMIENTO Y CONCLUSIÓN DE LA OBRA."

let estado;


const nuevaQueja = () => {
    let objform = $("#form_queja").serializeToJSON();

    Axios.post('https://sistemaintegral.conavi.gob.mx:81/api/nueva_solicitud', {

        curp: objform["form"]["curp"],
        formulario: objform["form"]["formulario"],
        data: "[" + JSON.stringify(objform) + "]",
        created_by: objform["form"]["usuario"]

    }).then(
        (response) => {
            if (response.data != "") {

                $(".secchechos").addClass("hidden");
                $(".secchechos-hide").addClass("hidden");
                $(".seccmensaje").removeClass("hidden")
                scroll("titulo_mensaje");

            } else {
                Alerta_("<strong>Ocurrió un error</strong> al registrar esta queja.", "alertaOp alert alert-danger alert-dismissible");

            }
        },

    ).catch(
        (err) => {
            console.log(err)
        });
}

const validarClases = (secc) => {

    var do_ = true

    var elementos = $(".error-" + secc).map(function () {
        return this.id;
    }).get();

    for (var i = 0; i < elementos.length; i++) {
        if ($("#" + elementos[i]).css("display") == "none") {

        }
        else {
            do_ = false;
            $("#" + elementos[i].replace("-error", "")).focus();
            scroll(elementos[i].replace("-error", ""));
            break;
        }

    }

    return do_;
}

const div_corrupcion = () => {
    $("#div_queja").css("display", "none");
    $("#div_corrupcion").css("display", "block");
    scroll("div_corrupcion");
}

const div_queja = (e) => {

    if (e != "") {
        $("#div_corrupcion").css("display", "none");
        switch (e) {
            case "card_maltrato": asigna_div_queja(
                titulo_int_patr, "CONDUCTAS AGRESIVAS Y VIOLENTAS HACIA MI PERSONA",
                "SI HAS SIDO VÍCTIMA DE MALOS TRATOS POR PARTE DE ALGÚN PRESTADOR DE SERVICIOS O PERSONAL DE LA CONAVI, ENTENDIENDO ESTOS COMO CUALQUIER AGRESIÓN FÍSICA O VERBAL, CUYA FINALIDAD SEA PROHIBIR, COACCIONAR, CONDICIONAR O INTIMIDARTE CON CUESTIONES RELACIONADAS AL OTORGAMIENTO DEL SUBSIDIO O DESARROLLO DE TU OBRA."); break;
            case "card_cobros": asigna_div_queja(
                titulo_int_patr, "CONDICIONAMIENTO DEL SUBSIDIO, AVANCE O CONCLUSIÓN DE OBRA A CAMBIO DE DINERO",
                "SI EL PRESTADOR DE SERVICIOS TE HA SOLICITADO ALGÚN PAGO NO CONSIDERADO EN EL CONTRATO Y REGLAS DE OPERACIÓN, CONDICIONANDO TU SUBSIDIO O EL AVANCE DE TU OBRA."); break;
            case "card_inconclusa": asigna_div_queja(titulo_obra, "CONSTRUCCIÓN SIN TERMINAR ",
                "SI EL PRESTADOR DE SERVICIOS NO CONCLUYE LA OBRA DE ACUERDO CON EL PROYECTO, EN EL PLAZO ESTABLECIDO."); break;
            case "card_savance": asigna_div_queja(titulo_obra, "RELACIONADAS CON EL INICIO O AVANCE DE LA OBRA",
                "SI EL PRESTADOR DE SERVICIOS NO HA INICIADO O AVANZADO LA OBRA, EN LOS TÉRMINOS ESTABLECIDOS EN EL CONTRATO."); break;
            case "card_diversa": asigna_div_queja(titulo_obra, "DIFERENCIA ENTRE LO CONSTRUIDO Y LO APROBADO EN EL PROYECTO.",
                "SI LOS TRABAJOS DE TU OBRA NO COINCIDEN CON EL PROYECTO VALIDADO POR LA CONAVI."); break;
            case "card_dsubsidio": asigna_div_queja(titulo_obra, "DIFERENCIA ENTRE LO CONSTRUIDO Y EL MONTO OTORGADO",
                "SI CONSIDERAS QUE LOS ALCANCES EN LA OBRA NO CORRESPONDEN AL MONTO DEL SUBSIDIO OTORGADO Y AL PROYECTO VALIDADO POR LA CONAVI."); break;
            case "card_dtrabajos": asigna_div_queja(titulo_obra, "SI DESEAS MANIFESTAR MALA CALIDAD EN LA EJECUCIÓN O DEFICIENCIAS EN LA OBRA",
                "SI LOS TRABAJOS REALIZADOS EN TU OBRA SON DE MALA CALIDAD O DEFICIENTES."); break;

        }
    }

}

const asigna_div_queja = (titulo, subtitulo, mensaje) => {
    $("#titulo").text(titulo);
    $("#subtitulo").text(subtitulo);
    $("#mensaje").text(mensaje);
    $("#div_queja").css("display", "block");
    scroll("div_queja");
}

const scroll = (id, block) => {
    if (block == "") {
        block = "center";
    }
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
}

const selecciona_queja = () => {
    $("#queja_selector").addClass("hidden");
    $("#queja_formulario").removeClass("hidden");
    $("#form_clasificacion").val($("#subtitulo").text());
    $("#form_tipo").val($("#titulo").text());
    $(".seccprincipal").removeClass("hidden");
    scroll("queja_formulario");
}

const validar = (id, require) => {
    var do_ = true;
    if ($("#" + id).val().length < require) {
        $("#" + id + "-error").show();
        $("#alert-" + id + "-error").addClass("hidden");

        do_ = false;

    } else {
        $("#" + id + "-error").hide();
    }
    return do_;
}

const validarCmb = (id) => {
    var do_ = true;
    if ($("#" + id).val() == 0) {
        $("#" + id + "-error").show();
        do_ = false;

    } else {
        $("#" + id + "-error").hide();
    }
    return do_;
}

const toggleSection = (opcion) => {
    switch (opcion) {
        case 99:
            $("#queja_selector").removeClass("hidden");
            $("#queja_formulario").addClass("hidden");
            $("#form_clasificacion").val("");
            $("#form_tipo").val("");
            $("#curp").prop("disabled", false);
            $("#curp").val("");
            limpiarActiva();
            $("#alert-existente").addClass("hidden");


            $("#titulo").text("");
            $("#subtitulo").text("");
            $("#mensaje").text("");
            $("#div_queja").css("display", "none");
            scroll("breadcrumb", "end");
            break;
        case 0:
            $("#cancel").removeClass("hidden");
            $("#confirm").addClass("hidden");
            $(".seccNombre").addClass("hidden");
            $(".cancelar").removeClass("hidden");
            scroll("div_queja");

            break;

        case 1:
            $("#cancel").addClass("hidden");
            $("#confirm").removeClass("hidden");
            $(".seccNombre").removeClass("hidden");
            $(".cancelar").addClass("hidden");
            scroll("div_queja");
            break;

        case 2:

            if ($(".rt-nombre").text() != "") {
                $("#cancel").removeClass("hidden");
                $("#confirm").addClass("hidden");
                $(".seccNombre").addClass("hidden");
                $(".cancelar").removeClass("hidden");
                $("#alert-existente").removeClass("hidden");
                $("#curp").prop("disabled", true);
                scroll("alert-existente");
            } else {

                $("#curp").prop("disabled", true);
                $(".seccGeneral").removeClass("hidden");
                $(".seccNombre-hide").addClass("hidden");
                scroll("titulo_principal");
            }

            break;
        case 3:
            $("#curp").prop("disabled", false);
            $(".seccGeneral").addClass("hidden");
            $(".seccNombre-hide").removeClass("hidden");
            scroll("div_queja");
            break;
        case 4:
            if (validarClases("seccGeneral")) {
                $(".seccGeneral").addClass("hidden");
                $(".seccprincipal").addClass("hidden");
                $(".seccNombre").addClass("hidden");
                $(".secchechos").removeClass("hidden");
                $(".secchechos-hide").removeClass("hidden");
                scroll("titulo_hechos");
            } else {

            }

            break;

        case 5:

            $(".seccGeneral").removeClass("hidden");
            $(".seccprincipal").removeClass("hidden");
            $(".seccNombre").removeClass("hidden");
            $(".secchechos").addClass("hidden");
            $(".secchechos-hide").addClass("hidden");
            scroll("titulo_principal");

            break;

        case 6:

            if (validarClases("secchechos")) {
                nuevaQueja();
            } else {

            }
            break;

        case 7:
            $(".seccprincipal").addClass("hidden");
            $(".cancelar").addClass("hidden");
            $("#seccactiva").removeClass("hidden");
            scroll("titulo_activa");
            break;


    }
}

const buscaCurp = (id, require, value) => {
    if (validar(id, require)) {
        $(".fa-bullhorn").toggleClass("fa fa-spin fa-spinner");
        $.get("https://sistemaintegral.conavi.gob.mx:81/api/renapoCurp/" + value.toUpperCase(), function (respuesta) {
            if (respuesta.statusOper === "EXITOSO") {
                solicitudActiva(value.toUpperCase(), "QUEJA O DENUNCIA", $("#form_clasificacion").val());
                $(".lblNombre").text(respuesta.nombres);
                $(".lblApellido1").text(respuesta.apellido1);
                $(".lblApellido2").text(respuesta.apellido2);
                $("#form_nombre").val(respuesta.nombres);
                $("#form_apellido1").val(respuesta.apellido1);
                $("#form_apellido2").val(respuesta.apellido2);
                $("#strCurp").val(respuesta.curp);
                $("#alert-curp-error").addClass("hidden");
                toggleSection(1);
                scroll("seccNombre");

            } else {
                $("#alert-curp-error").removeClass("hidden");
                toggleSection(0);

            }
            $(".fa-bullhorn").toggleClass("fa fa-spin fa-spinner");
        });
    } else {
        toggleSection(0);

    }
}

const buscaMunicipio = (id, id_estado) => {
    validarCmb(id);

    estado = id_estado;
    $(".municipios").remove();
    $(".localidades").remove();
    $(".fa-municipio").addClass("fa-spinner");
    $.getJSON("https://sistemaintegral.conavi.gob.mx:81/api/buscar_municipio/" + id_estado, function (respuesta) {
        try {
            $.each(respuesta, function (key, val) {
                $("#cmbMunicipio").append("<option class='municipios' value='" + val.id_municipio + "'>" + val.nombre_municipio + "</option>");
            });
        } catch (err) {

        }

    });

    $(".fa-municipio").removeClass("fa-spinner");

}

const buscaLocalidad = (id, id_municipio) => {
    validarCmb(id);
    $(".localidades").remove();
    $(".fa-localidad").addClass("fa-spinner");
    $.getJSON("https://sistemaintegral.conavi.gob.mx:81/api/buscar_localidad/" + estado + "/" + id_municipio, function (respuesta) {
        try {
            $.each(respuesta, function (key, val) {
                $("#cmbLocalidad").append("<option class='localidades' value='" + val.id_localidad + "'>" + val.nombre_localidad + "</option>");
            });
        } catch (err) {

        }

    });
    $(".fa-localidad").removeClass("fa-spinner");

}

const botones = (id) => {
    switch (id) {
        case "cancelar":
            toggleSection(99);
            $("#curp").val("");
            break;
        case "nombre_incorrecto":
            toggleSection(0);
            $("#curp").val("");
            break;
        case "nombre_correcto":
            toggleSection(2);
            break;
        case "atras_general":
            toggleSection(3);
            break;
        case "siguiente_general":
            toggleSection(4);
            break;
        case "atras_hechos":
            toggleSection(5);
            break;
        case "enviar":
            toggleSection(6);
            break;
        case "terminar":
            window.location.replace("/dac_2022_/#/Panel");
            break;
        case "ver_status":
            toggleSection(7);
            break
    }
}

const solicitudActiva = (curp, formulario, clasificacion) => {

    limpiarActiva();
    $.getJSON("https://sistemaintegral.conavi.gob.mx:81/api/buscar_solicitud_tipo/" + curp + "/" + formulario + "/" + clasificacion, function (respuesta) {
        console.log(respuesta)

        $.each(respuesta, function (key, val) {

            $.each(JSON.parse(val.data)[0]["form"], function (key, val) {
                $(".rt-" + key).text(val + "");
            });

        });

    });



}

const limpiarActiva = () => {
    $(".rt-nombre").text("");
    $(".rt-apellido1").text("");
    $(".rt-apellido2").text("");
    $(".rt-tipo").text("");
    $(".rt-clasificacion").text("");
    $(".rt-narrativa").text("");
}

const out = () => {
    localStorage.clear();
    window.location.href = "/dac_2022_/#/Admin";
}

function isLoged() {
    const isLogged = localStorage.getItem('credenciales_dac');
    if (!isLogged) {
        out();

    } else {
        let user = JSON.parse(localStorage.getItem('credenciales_dac'));

        $.each(user, function (key, val) {
            $("#form_usuario").val(val.email);
        });


    }
}

function Inicio(props) {

    useEffect(() => {
        isLoged();
    });

    return (
        <div className="Inicio">
            <header>


                <Accesibilidad />
                <Alerta />
                <GaleriaHeader />


                <div id="cont" class="container">

                    <ol id="breadcrumb" class="breadcrumb">
                        <li><a href=""><i class="icon icon-home"></i></a></li>
                        <li class=""><Link class="nav-link" to="/Panel">Panel de administrador</Link></li>
                        <li class="active"><Link class="nav-link">Quejas y denuncias</Link></li>
                    </ol>

                    <form id="form_queja">

                        <div id="params">
                            <input type="hidden" id="form_formulario" name="form.formulario" value="QUEJA O DENUNCIA" />
                            <input type="hidden" id="form_usuario" name="form.usuario" value="PORTAL" />
                            <input type="hidden" id="form_tipo" name="form.tipo" />
                            <input type="hidden" id="form_clasificacion" name="form.clasificacion" />

                            <input type="hidden" id="form_nombre" name="form.nombre" />
                            <input type="hidden" id="form_apellido1" name="form.apellido1" />
                            <input type="hidden" id="form_apellido2" name="form.apellido2" />

                        </div>

                        <div id="queja_selector">
                            <div class="col-md-3 bottom-buffer-x15 dropdown-at dropbtn-at"
                                onClick={(e) => div_queja(e.target.id)}>
                                <div id="card_maltrato" class="card card-center">
                                    <i id="card_maltrato" style={{ fontSize: 50 }} class="fas fa-frown red-ic"></i>

                                    <p id="card_maltrato" class="card-tittle red">MALTRATO A LA PERSONA BENEFICIARIA</p>
                                    <div class="dropdown-content-at">
                                        <div class="option-dropdown-at">
                                            <i class="fas fa-info-circle"></i>
                                            <p>Conductas agresivas y violentas hacia mi persona.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-3 bottom-buffer-x15 dropdown-at dropbtn-at"
                                onClick={(e) => div_queja(e.target.id)}>
                                <div id="card_cobros" class="card card-center">
                                    <i id="card_cobros" style={{ fontSize: 50 }} class="fas fa-comment-dollar red-ic"></i>

                                    <p id="card_cobros" class="card-tittle red">COBROS INDEBIDOS</p>
                                    <div class="dropdown-content-at">
                                        <div class="option-dropdown-at">
                                            <i class="fas fa-info-circle"></i>
                                            <p>Condicionamiento del subsidio, avance o conclusión de obra a cambio de dinero .</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-3 bottom-buffer-x15 dropdown-at dropbtn-at"
                                onClick={(e) => div_queja(e.target.id)}>
                                <div id="card_inconclusa" class="card card-center">
                                    <i id="card_inconclusa" style={{ fontSize: 50 }} class="fas fa-house-damage red-ic"></i>

                                    <p id="card_inconclusa" class="card-tittle red">OBRA INCONCLUSA</p>

                                    <div class="dropdown-content-at">
                                        <div class="option-dropdown-at">
                                            <i class="fas fa-info-circle"></i>
                                            <p>Construcción sin terminar .</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3 bottom-buffer-x15 dropdown-at dropbtn-at"
                                onClick={(e) => div_queja(e.target.id)}>
                                <div id="card_savance" class="card card-center">
                                    <i id="card_savance" style={{ fontSize: 50 }} class="fas fa-stopwatch red-ic"></i>

                                    <p id="card_savance" class="card-tittle red">OBRA SIN AVANCE</p>
                                    <div class="dropdown-content-at">
                                        <div class="option-dropdown-at">
                                            <i class="fas fa-info-circle"></i>
                                            <p>Relacionadas con el inicio o avance de la obra.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-3 bottom-buffer-x15 dropdown-at dropbtn-at"
                                onClick={(e) => div_queja(e.target.id)}>
                                <div id="card_diversa" class="card card-center">
                                    <i id="card_diversa" style={{ fontSize: 50 }} class="fas fa-thumbs-down red-ic"></i>

                                    <p id="card_diversa" class="card-tittle red">OBRA DIVERSA AL PROYECTO</p>
                                    <div class="dropdown-content-at">
                                        <div class="option-dropdown-at">
                                            <i class="fas fa-info-circle"></i>
                                            <p>Diferencia entre lo construido y lo aprobado en el proyecto.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-3 bottom-buffer-x15 dropdown-at dropbtn-at"
                                onClick={(e) => div_queja(e.target.id)}>
                                <div id="card_dsubsidio" class="card card-center">
                                    <i id="card_dsubsidio" style={{ fontSize: 50 }} class="fas fa-balance-scale-right red-ic"></i>

                                    <p id="card_dsubsidio" class="card-tittle red">APLICACIÓN DEFICIENTE DEL SUBSIDIO</p>

                                    <div class="dropdown-content-at">
                                        <div class="option-dropdown-at">
                                            <i class="fas fa-info-circle"></i>
                                            <p>Diferencia entre lo construido y el monto otorgado.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3 bottom-buffer-x15 dropdown-at dropbtn-at"
                                onClick={(e) => div_queja(e.target.id)}>
                                <div id="card_dtrabajos" class="card card-center">
                                    <i id="card_dtrabajos" style={{ fontSize: 50 }} class="fas fa-times-circle red-ic"></i>

                                    <p id="card_dtrabajos" class="card-tittle red">TRABAJOS DEFICIENTES</p>
                                    <div class="dropdown-content-at">
                                        <div class="option-dropdown-at">
                                            <i class="fas fa-info-circle"></i>
                                            <p>Trabajos y materiales de mala calidad .</p>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-3 bottom-buffer-x15 dropdown-at dropbtn-at"
                                onClick={div_corrupcion}>
                                <div id="card_corrupcion" class="card card-center">
                                    <i id="card_corrupcion" style={{ fontSize: 50 }} class="fas fa-user-secret red-ic"></i>
                                    <p id="card_corrupcion" class="card-tittle red">CORRUPCIÓN</p>

                                    <div class="dropdown-content-at">
                                        <div class="option-dropdown-at">
                                            <i class="fas fa-info-circle"></i>
                                            <p>Faltas o conductas corruptas de servidores públicos.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div id="div_queja" class="div_queja">
                                <h4 id="titulo" class="text-center div-queja-text"></h4>
                                <p id="subtitulo" class="text-center div-queja-text"></p>
                                <div class="separador"></div>
                                <p id="mensaje" class="div-queja-text"></p>
                                <div class="area-footer">
                                    <button onClick={selecciona_queja} style={{ margin: 0 }} class="btn-sm btn-primary" type="button"><span class="fas fa-angle-right"></span> Seleccionar</button>
                                </div>
                            </div>

                            <div id="div_corrupcion" class="div_corrupcion">
                                <h4 class="text-center div-queja-text">ACTOS DE CORRUPCIÓN</h4>
                                <p class="text-center div-queja-text">Si ha presenciado algún acto de corrupción por parte de servidores públicos, la Secretaría de la Función Pública brinda atención ciudadana a través de los siguientes canales:</p>
                                <div class="separador"></div>
                                <p class="div-queja-text">



                                </p>
                                <p class="div-queja-text">
                                    <i class="text-dot"></i>Vía correspondencia: Enviar escrito a la Dirección General de Denuncias e Investigaciones de la Secretaría de la Función Pública en Av. Insurgentes Sur No. 1735, Piso 2 Ala Norte, Guadalupe Inn, Álvaro Obregón, CP 01020, Ciudad de México.
                                </p>
                                <p class="div-queja-text">
                                    <i class="text-dot"></i>Vía telefónica: En el interior de la República al 800 11 28 700 y en la Ciudad de México 55 2000 2000.
                                </p>
                                <p class="div-queja-text">
                                    <i class="text-dot"></i>Presencial: en el módulo 3 de la Secretaría de la Función Pública ubicado en Av. Insurgentes Sur 1735, PB, Guadalupe Inn, Álvaro Obregón, Código Postal 01020, Ciudad de México.
                                </p>
                                <p class="div-queja-text">
                                    <i class="text-dot"></i>Plataforma "Ciudadanos Alertadores Internos y Externos de la Corrupción": Diseñada para atender actos graves de corrupción, en los que se encuentren involucradas personas servidoras públicas federales y se requiera confidencialidad. Pueden alertar: Cohecho, Peculado y Desvío de recursos públicos: https://alertadores.funcionpublica.gob.mx
                                </p>
                                <p class="div-queja-text">
                                    <i class="text-dot"></i>Aplicación informática "Denuncia Ciudadana de la corrupción"
                                    <br />
                                    <br />
                                    <a href="https://apps.apple.com/mx/app/denuncia-ciudadana-corrupci%C3%B3n/id1171271018"><i class="fab fa-apple red-ic p-text"></i> iOS</a>
                                    <br />
                                    <a href=" https://play.google.com/store/apps/details?id=sfp.denunciaciudadana.appmovil&hl=es_MX&gl=US"><i class="fab fa-android red-ic p-text"></i> Android</a>
                                </p>



                                <div class="area-footer">

                                </div>
                            </div>
                        </div>

                        <div id="queja_formulario" class="hidden">
                            <div class="div_queja_form">

                                <section class="seccprincipal">
                                    <h4 id="titulo_principal" class="text-center">DATOS DE CONTACTO</h4>
                                    <p style={{ fontSize: 14 }} id="subtitulo" class=""><em>Tus datos personales se encuentran protegidos en términos de lo señalado por las leyes y demás disposiciones aplicables en materia de Transparencia y Protección de Datos Personales.</em></p>

                                    <div class="row bottom-buffer-x15">
                                        <div class="col-md-12" style={{ fontSize: 60, textAlign: "center" }}>
                                            <div class="features-icons-item mx-auto mb-0 mb-lg-3">
                                                <div class="features-icons-icon d-flex">
                                                    <i style={{ fontSize: 80 }} class="fas fa-bullhorn gold"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div style={{ fontSize: 12 }} class="col-md-6 alerta-sm alert alert-info">
                                            <i class="fas fa-bookmark"></i> PARA BRINDARTE UNA MEJOR ATENCIÓN, ES NECESARIO QUE NOS PROPORCIONES TU CURP.
                                        </div>
                                        <div class="col-md-6">
                                            <input
                                                style={{ textTransform: "uppercase" }}
                                                onChange={(e) => buscaCurp(e.target.id, e.target.maxLength, e.target.value)}
                                                name="form.curp" type="text" class="form-control text-center" maxlength="18" id="curp" placeholder="Ingresa tu CURP" aria-invalid="false" />
                                            <label id="curp-error" class="error" for="curp" >La CURP debe contener 18 dígitos</label>
                                            <div id="alert-curp-error" class="alerta-sm hidden alert alert-danger" >No se encontraron los datos relacionados a la CURP</div>
                                        </div>
                                    </div>
                                    <div id="alert-existente" class="row justify-content-center hidden">
                                        <div class="col-md-4">
                                        </div>
                                        <div style={{ fontSize: 12 }} class="col-md-4 alerta-sm alert alert-warning seccNombre-hide">
                                            <i class="fas fa-portrait gold"></i>
                                            <p>BIENVENID@ <label class="rt-nombre"></label>, HEMOS ENCONTRADO QUE YA HAS REALIZADO UNA QUEJA O DENUNCIA.</p>
                                            <br />
                                            <button onClick={(e) => botones(e.target.id)} style={{ margin: 0 }} class="btn-sm btn-primary" type="button" id="ver_status">
                                                AQUÍ <span class="fas fa-chevron-right"></span>
                                            </button>


                                        </div>
                                        <div class="col-md-4">
                                        </div>
                                    </div>
                                </section>

                                <div class="top-buffer area-footer-form cancelar">
                                    <div id="cancel" class="row">
                                        <div class="col-md-6 bottom-buffer-x15" style={{ textAlign: "start" }}>
                                            <button onClick={(e) => botones(e.target.id)} style={{ margin: 0 }} class="btn-sm btn-default" type="button" id="cancelar">
                                                <span class="fas fa-chevron-left"></span> Atrás
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <section class="pg1 seccNombre hidden" id="seccNombre">
                                    <div class="row">
                                        <div id="alert-confirm" class="row justify-content-center">
                                            <div class="col-md-4">
                                            </div>
                                            <div style={{ fontSize: 12 }} class="col-md-4 alerta-sm alert alert-warning seccNombre-hide">
                                                <i class="fas fa-bookmark gold"></i><br /> POR FAVOR CONFIRMA QUE EL NOMBRE ES CORRECTO.
                                            </div>
                                            <div class="col-md-4">
                                            </div>
                                        </div>

                                        <div class="col-md-4 text-center">
                                            <small>Nombre(s)</small><br /><label class="lblNombre"><strong style={{ fontsize: 15 }}></strong></label>
                                        </div>
                                        <div class="col-md-4 text-center">
                                            <small>Primer Apellido</small><br /><label class="lblApellido1"><strong style={{ fontsize: 15 }}></strong></label>
                                        </div>
                                        <div class="col-md-4 text-center">
                                            <small>Segundo Apellido</small><br /><label class="lblApellido2"><strong style={{ fontsize: 15 }}></strong></label>
                                        </div>
                                    </div>
                                </section>

                                <div class="seccNombre seccNombre-hide top-buffer area-footer-form hidden">

                                    <div id="confirm" class="row">

                                        <div class="col-md-6 bottom-buffer-x15" style={{ textAlign: "start" }}>
                                            <button onClick={(e) => botones(e.target.id)} style={{ margin: 0 }} class="btn-sm btn-default" type="button" id="nombre_incorrecto">
                                                <span class="fas fa-times-circle"></span> No es correcto
                                            </button>
                                        </div>

                                        <div class="col-md-6 bottom-buffer-x15" style={{ textAlign: "end" }}>
                                            <button onClick={(e) => botones(e.target.id)} style={{ margin: 0 }} class="btn-sm btn-primary" type="button" id="nombre_correcto">
                                                <span class="fas fa-check-circle"></span> Si, es correcto
                                            </button>
                                        </div>



                                    </div>

                                </div>

                                <section class="pg1 seccGeneral hidden" id="seccGeneral">
                                    <div class="row top-buffer">
                                        <div class="col-md-6">
                                            <small>Vía de recepción</small>
                                            <select class="form-control" id="cmbrecepcion" name="form.recepcion"
                                                onChange={(e) => validarCmb(e.target.id)}>
                                                <option value="">SELECCIONE VÍA DE RECEPCIÓN</option>
                                                <option value="REDES SOCIALES">REDES SOCIALES</option>
                                                <option value="CORREO ELECTRÓNICO">CORREO ELECTRÓNICO</option>
                                                <option value="LLAMADA TELEFÓNICA">LLAMADA TELEFÓNICA</option>
                                                <option value="DG">DG</option>
                                                <option value="SIDAC">SIDAC</option>
                                                <option value="ATENCIÓN PRESENCIAL">ATENCIÓN PRESENCIAL</option>
                                                <option value="OFICIALÍA DE PARTES">OFICIALÍA DE PARTES</option>
                                                <option value="CONAVI TE ATIENDE">CONAVI TE ATIENDE</option>
                                                <option value="SOTA">SOTA</option>
                                                <option value="SECRETARIA DE BIENESTAR">SECRETARIA DE BIENESTAR</option>
                                            </select>
                                            <label id="cmbrecepcion-error" class="error error-seccGeneral" for="cmbrecepcion">Indique la vía de recepción de la solicitud</label>
                                        </div>


                                    </div>
                                    <div class="row">

                                        <div class="col-md-4">
                                            <small>Teléfono de Contacto (1)</small>
                                            <input onChange={(e) => validar(e.target.id, e.target.maxLength)} onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} name="form.telefono" type="text" class="form-control text-center telefono error" maxlength="10" id="telefono" aria-invalid="true" />
                                            <label id="telefono-error" class="error error-seccGeneral" for="telefono">Indique un número telefónico a 10 dígitos</label>
                                        </div>
                                        <div class="col-md-4  text-center">
                                            <small>Teléfono de Contacto (2)</small>
                                            <input name="form.telefono2" type="text" class="form-control text-center telefono valid" maxlength="10" id="telefono2" />
                                        </div>
                                        <div class="col-md-4 text-center">
                                            <small>Correo Electrónico</small>
                                            <input name="form.correo" type="text" class="form-control text-center correo" id="strCorreo" />
                                            <label id="correo-error" class="error" for="correo"></label>
                                        </div>

                                        <div class="col-md-12 text-center">
                                            <small>Si eres integrante de algún Comité de Contraloría Social, especifica cuál:</small>
                                            <input name="form.comite" type="text" class="form-control text-center comite" id="comite" />
                                        </div>
                                        <div class="col-md-12  text-center"><label></label></div>
                                    </div>

                                </section>

                                <div class="top-buffer area-footer-form hidden seccGeneral" id="seccGeneral">

                                    <div id="btn-seccGeneral" class="row">

                                        <div class="col-md-6 bottom-buffer-x15" style={{ textAlign: "start" }}>
                                            <button onClick={(e) => botones(e.target.id)} style={{ margin: 0 }} class="btn-sm btn-default" type="button" id="atras_general">
                                                <span class="fas fa-chevron-left"></span> Atrás
                                            </button>
                                        </div>

                                        <div class="col-md-6 bottom-buffer-x15" style={{ textAlign: "end" }}>
                                            <button onClick={(e) => botones(e.target.id)} style={{ margin: 0 }} class="btn-sm btn-primary" type="button" id="siguiente_general">
                                                Siguiente <span class="fas fa-chevron-right"></span>
                                            </button>
                                        </div>



                                    </div>

                                </div>

                                <section id="secchechos" class="pg2 hidden secchechos" >
                                    <h4 id="titulo_hechos" class="text-center">DATOS DE CONTACTO</h4>
                                    <p style={{ fontSize: 14 }} id="subtitulo" class=""><em>Tus datos personales se encuentran protegidos en términos de lo señalado por las leyes y demás disposiciones aplicables en materia de Transparencia y Protección de Datos Personales.</em></p>

                                    <div class="row bottom-buffer-x15">
                                        <div class="col-md-12" style={{ fontSize: 60, textAlign: "center" }}>
                                            <div class="features-icons-item mx-auto mb-0 mb-lg-3">
                                                <div class="features-icons-icon d-flex">
                                                    <i style={{ fontSize: 80 }} class="fas fa-bullhorn gold"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class=" col-md-4">
                                            <small>¿Cuándo ocurrieron los hechos?</small>
                                            <input onChange={(e) => validar(e.target.id)}
                                                type="date" class="form-control" name="form.cuando" id="cuando" max="2021-09-03" maxLength="10" />
                                            <label id="cuando-error" class="error error-secchechos" for="cuando">Indique cuándo ocurrieron los hechos</label>
                                            <div class="text-center" ></div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="form-group  col-md-12">
                                            <small>Selecciona la Entidad donde ocurrieron los hechos </small>
                                            <i class="fa-municipio gold fa fa-spin"></i>
                                            <select
                                                onChange={(e) => buscaMunicipio(e.target.id, e.target.value)}
                                                class="form-control EdoMunLoc cmbEntidad" name="form.entidad" id="cmbEntidad"><option value="0" selected="">SELECCIONE ENTIDAD</option><option value="1">AGUASCALIENTES</option><option value="2">BAJA CALIFORNIA</option><option value="3">BAJA CALIFORNIA SUR</option><option value="4">CAMPECHE</option><option value="5">COAHUILA</option><option value="6">COLIMA</option><option value="7">CHIAPAS</option><option value="8">CHIHUAHUA</option><option value="9">CIUDAD DE MÉXICO</option><option value="10">DURANGO</option><option value="11">GUANAJUATO</option><option value="12">GUERRERO</option><option value="13">HIDALGO</option><option value="14">JALISCO</option><option value="15">MÉXICO</option><option value="16">MICHOACÁN</option><option value="17">MORELOS</option><option value="18">NAYARIT</option><option value="19">NUEVO LEÓN</option><option value="20">OAXACA</option><option value="21">PUEBLA</option><option value="22">QUERÉTARO</option><option value="23">QUINTANA ROO</option><option value="24">SAN LUIS POTOSÍ</option><option value="25">SINALOA</option><option value="26">SONORA</option><option value="27">TABASCO</option><option value="28">TAMAULIPAS</option><option value="29">TLAXCALA</option><option value="30">VERACRUZ</option><option value="31">YUCATÁN</option><option value="32">ZACATECAS</option></select>
                                            <label id="cmbEntidad-error" class="error error-secchechos" for="cmbEntidad">Indique la Entidad donde ocurrieron los hechos</label>
                                        </div>

                                        <div class="form-group  col-md-12">
                                            <small>Selecciona el Municipio donde ocurrieron los hechos</small>
                                            <i class="fa-localidad gold fa fa-spin"></i>
                                            <select class="form-control EdoMunLoc" name="form.municipio" id="cmbMunicipio"
                                                onChange={(e) => buscaLocalidad(e.target.id, e.target.value)}>
                                                <option value="0">SELECCIONE MUNICIPIO</option>
                                            </select>
                                            <label id="cmbMunicipio-error" class="error error-secchechos" for="cmbMunicipio">Indique el Municipio donde ocurrieron los hechos</label>
                                        </div>

                                        <div class="form-group  col-md-12">
                                            <small>Selecciona la Localidad donde ocurrieron los hechos</small>
                                            <select
                                                onChange={(e) => validarCmb(e.target.id)}
                                                class="form-control EdoMunLoc" name="form.localidad" id="cmbLocalidad">
                                                <option value="0">SELECCIONE LOCALIDAD</option>
                                            </select>
                                            <label id="cmbLocalidad-error" class="error error-secchechos" for="cmbLocalidad">Indique la Localidad donde ocurrieron los hechos</label>
                                        </div>

                                        <div class="form-group  col-md-12">
                                            <small>Narra el motivo de su denuncia</small>
                                            <textarea
                                                onChange={(e) => validar(e.target.id, e.target.minLength)}
                                                class="form-control rounded-0 text-uppercase" name="form.narrativa" id="narrativa" rows="5" minLength="30" maxlength="10000"></textarea>
                                            <label id="narrativa-error" class="error error-secchechos" for="narrativa">Por favor proporcione más detalles de los hechos</label>
                                        </div>
                                    </div>

                                    {/*<div class="row">
                                        <div class="col-md-12">
                                            <p>Si tienes pruebas de los hechos, adjúntelos</p>
                                            <i class="fas fa-photo-video gold"></i> Fotografías, audio o video
                                            <input type="file" class="pruebas" name="video" id="Media" />
                                            <i class="fas fa-file-alt gold"></i> Documentación
                                            <input type="file" class="pruebas" name="documento" id="Doc" />
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <p class="pull-left" id="noticeMb">Máximo 25 MB por todos los archivos</p>
                                            <p class="pull-right" id="totalMb">Total 0.00 MB</p>
                                        </div>
                                        </div>*/}
                                </section>

                                <div class="top-buffer area-footer-form hidden secchechos-hide">

                                    <div id="confirm" class="row">

                                        <div class="col-md-6 bottom-buffer-x15" style={{ textAlign: "start" }}>
                                            <button onClick={(e) => botones(e.target.id)} style={{ margin: 0 }} class="btn-sm btn-default" type="button" id="atras_hechos">
                                                <span class="fas fa-chevron-left"></span> Atrás
                                            </button>
                                        </div>

                                        <div class="col-md-6 bottom-buffer-x15" style={{ textAlign: "end" }}>
                                            <button onClick={(e) => botones(e.target.id)} style={{ margin: 0 }} class="btn-sm btn-primary" type="button" id="enviar">
                                                <span class="fas fa-check-circle"></span> Enviar
                                            </button>
                                        </div>



                                    </div>

                                </div>

                                <section id="seccmensaje" class="seccmensaje hidden" >
                                    <div class="card-header text-center">
                                        <h3 id="titulo_mensaje" class="h3Msg">QUEJA O DENUNCIA</h3>
                                    </div>
                                    <legend class=""></legend>
                                    <div class="msgBody">
                                        <div class="">
                                            <div class="" style={{ textAlign: "justify" }}>
                                                <p>Tu queja o denuncia ha ingresado al sistema.</p>
                                                <p>A partir de este momento podrás realizar el seguimiento del proceso con tu CURP <strong id="strCurp"></strong>. En breve nos pondremos en contacto contigo para confirmar información.</p>
                                                <p>Te recordamos que tus datos <strong>son confidenciales y están protegidos</strong> por la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados.
                                                    <br /><br /><a class="enlace" href="Aviso de privacidad.pdf" target="_blank"><p><em><strong>**Consulta nuestro aviso de privacidad**</strong></em></p></a>
                                                    <br /><br />Si tienes alguna duda, con gusto te atenderemos de lunes a viernes de 09:00 a 15:00 y de 17:00 a 19:00 horas (Hora de la CDMX).</p>


                                                <p class="p-center">Dirección de Atención Ciudadana</p>
                                                <p class="p-center">Contacto:</p>
                                                <p class="p-center"><i class="fab fa-whatsapp gold"></i> </p>
                                                <p class="p-center">Conavi Te Atiende<br />55.59.64.17.13<br />Solo mensajes de WhatsApp</p>
                                                <p class="light-gold"><em><strong>“La Comisión Nacional de Vivienda no cuenta, ni trabaja con gestores</strong>, todos los recursos de <strong>los apoyos</strong> de los Programas <strong>son subsidios</strong>, por lo que <strong>no existe cobro alguno de recuperación y todos los trámites</strong> que se realicen para acceder a dichos apoyos, <strong>son gratuitos”.</strong></em></p>
                                            </div>
                                            <div>
                                                <button onClick={(e) => botones(e.target.id)} style={{ margin: 0 }} class="pull-right btn-sm btn-primary" type="button" id="terminar">
                                                    Terminar <span class="fas fa-chevron-right"></span>
                                                </button>
                                                <div class="clearfix"></div>
                                            </div></div></div>
                                </section>

                                <section id="seccactiva" class="seccactiva hidden" >
                                    <div class="card-header text-center">
                                        <h3 id="titulo_activa" class="h3Msg">QUEJA O DENUNCIA</h3>
                                    </div>
                                    <legend class=""></legend>
                                    <div class="msgBody">
                                        <div class="">
                                            <div class="" style={{ textAlign: "justify" }}>

                                                <p> <label style={{ marginRight: 5 }} class="rt-nombre"></label>
                                                    <label style={{ marginRight: 5 }} class="rt-apellido1"></label>
                                                    <label class="rt-apellido2"></label>
                                                </p>
                                                <p>TIPO: <label class="rt-tipo"></label></p>
                                                <p>CLASIFICACIÓN: <label class="rt-clasificacion"></label></p>
                                                <p>NARRATIVA: <label class="rt-narrativa"></label></p>


                                                <p>Te recordamos que tus datos <strong>son confidenciales y están protegidos</strong> por la Ley de Protección de Datos Personales en Posesión de Sujetos Obligados.
                                                    <br /><br />Si tienes alguna duda, con gusto te atenderemos de lunes a viernes de 09:00 a 15:00 y de 17:00 a 19:00 horas (Hora de la CDMX).</p>
                                                <p class="p-center">Dirección de Atención Ciudadana</p>
                                                <p class="p-center">Contacto:</p>
                                                <p class="p-center"><i class="fab fa-whatsapp gold"></i> </p>
                                                <p class="p-center">Conavi Te Atiende<br />55.59.64.17.13<br />Solo mensajes de WhatsApp</p>
                                            </div>
                                            <div>
                                                <button onClick={(e) => botones(e.target.id)} style={{ margin: 0 }} class="pull-right btn-sm btn-primary" type="button" id="terminar">
                                                    Terminar <span class="fas fa-chevron-right"></span>
                                                </button>
                                                <div class="clearfix"></div>
                                            </div></div></div>
                                </section>

                            </div>
                        </div>
                    </form>
                </div>

                <div class="colofon red-text text-center">
                    <div class="row row-fixed">
                        <div class="col-lg-12 my-auto showcase-text">
                            <p class="lead mb-0 p-center">
                                Avenida H. Escuela Naval Militar, número 669, piso 2 y 3, Colonia Presidentes Ejidales 1ra. Sección, Alcaldía Coyoacán, C.P. 04470, Ciudad de México.
                                <br></br>Teléfono: 55.91.38.99.91<br></br>Opción 1<br></br>atencionciudadana@conavi.gob.mx
                            </p>
                        </div>
                    </div>
                </div>

            </header>
        </div >
    );
}

export default Inicio;