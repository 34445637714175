import $ from 'jquery';
import Accesibilidad from '../components/Accesibilidad'
import GaleriaHeader from '../components/GaleriaHeader'
import Alerta from '../components/Alerta';
import Alerta_ from '../assets/js/Alerta.js';
//import Video from './dac_2022_/VIDEO.mp4';
//import Video1 from './dac_2022_/VIDEO1.mp4';
import PVS from '../assets/img/pvs.jpg';
import PNR from '../assets/img/pnr.jpg';
import cdyo from '../assets/img/cdyo.png';
import Modalcurp from '../components/modal/Modalcurp'
import Modaline from '../components/modal/Modaline'
import '../assets/css/scroll.css';
import '../assets/css/navbar.css';
import '../assets/css/content.css';
import '../assets/css/emergente.css';
import '../assets/css/datepicker.css';
import '../assets/js/modales.js';


import { useEffect, useState } from 'react';
import { Link, withRouter } from "react-router-dom";
import ModalRecurso from '../components/modal/ModalRecurso.jsx';

const scroll = (id) => {
	let el = document.getElementById(id);
	el.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
}

const scrollFocus = (id) => {
	let el = document.getElementById(id);
	el.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
	$("#" + id).addClass("shine");
}

const buscarBeneficiario = (button) => {


	$(".dropdown-content-at-md").css("display", "none");
	if ($("#txtCurp").val().length == 18) {
		$("#cdyo").addClass("hidden");
		$("#cdyo_").addClass("hidden");
		$(".btnBcurp").addClass("fa fa-spin fa-spinner");
		$("#btnBcurp").prop('disabled', true);

		$.getJSON("https://sistemaintegral.conavi.gob.mx:81/api/buscar_beneficiario/" + $("#txtCurp").val(), function (respuesta) {
			var res = "";
			var nombre = "";
			var programa = "";
			$(".res-registo").remove(".res")

			try {
				$.each(respuesta, function (key, val) {
					nombre = val.nombre;
					programa = val.programa;
				});

				if (nombre == "") {
					res = "<p class='res'>LO SENTIMOS, <strong>NO ESTÁS REGISTRADA/O</strong> COMO PERSONA BENEFICIARIA DE LOS PROGRAMAS QUE EJECUTA LA CONAVI.</p>"
					$(".res-registo").find("p").remove(".res");
					$(".res-registo").append(res);
					$(".res-derechos").css("display", "none");
					$(".res-solicitar").css("display", "block");
					$(".dropdown-content-at-md").css("display", "block");

				} else {
					res = "<p class='res'>BIENVENIDA/O <strong>" + nombre + "</strong> ERES PERSONA BENEFICIARIA DEL <strong>" + programa + "</strong></p>"
					$(".res-registo").find("p").remove(".res");
					$(".res-registo").append(res);
					$(".res-derechos").css("display", "block");
					$(".res-solicitar").css("display", "none");
					$(".dropdown-content-at-md").css("display", "block");
					$("#cdyo").removeClass("hidden");
					$("#cdyo_").removeClass("hidden");

				}
				$(".btnBcurp").removeClass("fa fa-spin fa-spinner");
				$("#btnBcurp").prop('disabled', false);
				scroll("response_cnv");
			} catch (err) {

			}






		});

	} else {
		new Alerta_("La CURP debe contener 18 dígitos", "alertaOp alert alert-danger alert-dismissible");
	}

}

function Inicio(props) {
	const [fInicial, setfInicial] = useState(new Date());
	const [fFinal, setfFinal] = useState(new Date());

	useEffect(() => {
		$("#modal").css("display", "block");
		$("#content-rec").css("display", "block");

	});
	return (
		<div className="Inicio">
			<header>


				<Accesibilidad />
				<Alerta />
				<GaleriaHeader />
				<div id="modal" class="modal">
					<Modalcurp />
					<Modaline />
					<ModalRecurso />

				</div>



				<div id="cont" class="container">

					<em><p class="blue" style={{ fontSize: 30, marginTop: 40 }}>Este espacio es un canal abierto para el diálogo con la Institución, a través de él
						<strong> atenderemos tus dudas o inquietudes </strong>
						sobre algún asunto de tu interés relacionado con la Conavi,
						<strong> también podrás presentar sugerencias, quejas o denuncias </strong> vinculadas con nuestros Programas.</p></em>
					<ol class="breadcrumb">
						<li><a href=""><i class="icon icon-home"></i></a></li>
						<li class="active"><Link class="nav-link" to="/Inicio">Inicio</Link></li>
					</ol>

					{/*<div class="row">
						<div id="bodyVideo" class="col-md-12 bottom-buffer">
							<h2>¿Quiénes somos?</h2>
							<hr class="red bottom-buffer"></hr>
							<video style={{ width: "100%", marginTop: 20 }} playsinline="playsinline" autoplay="autoplay" controls>
								<source src={Video} type="video/mp4" />
							</video>
						</div>
					</div>*/}


					<h2>La Comisión Nacional de Vivienda (Conavi)</h2>
					<hr class="red bottom-buffer"></hr>
					<div class="row bottom-buffer">

						<div class="col-md-7">
							<p>Es una entidad del Gobierno de México que otorga subsidios para una vivienda adecuada, dirigidos a las personas que más lo necesitan. Esto significa que todas las personas son importantes para nosotros.</p>
							<div class="div-color  bottom-buffer-x15">
								<h5>OBJETIVO PRIORITARIO DE LA CONAVI</h5>
								<hr class="red bottom-buffer"></hr>
								<p>Disminuir la carencia de una vivienda adecuada de la población en situación de vulnerabilidad por condiciones sociodemográficas, por riesgo o por precariedad de su vivienda. Para lograrlo, la Conavi ejecuta el Programa de Vivienda Social y el Programa Nacional de Reconstrucción en el Componente de Vivienda.</p>
								<a href="https://www.gob.mx/conavi/documentos/programa-institucional-2020-2024-de-la-comision-nacional-de-vivienda-pi-conavi-2020-2024" target="blank_"><p style={{ fontWeight: "bold" }} class="red"> <i class="far fa-arrow-alt-circle-right"></i><em> Programa Institucional 2020-2024 de la Comisión Nacional de Vivienda (PI-Conavi 2020-2024)</em></p></a>
							</div>
							{/*<div class="bottom-buffer">
								<p class="green font-xl">PROGRAMA NACIONAL DE RECONSTRUCCIÓN <em style={{ fontSize: 12 }}>Componente de vivienda</em></p>
								<img src={PNR} class="img-responsive bottom-buffer" />
								<p ><strong>Objetivo específico del componente Vivienda:</strong> Contribuir a proteger y garantizar la realización del derecho a disfrutar de una vivienda adecuada para las personas y comunidades afectadas por los SISMOS, que aún no han sido atendidas o lo fueron parcialmente, mediante acciones de reconstrucción, rehabilitación y reubicación de vivienda.</p>
								<a href="https://www.gob.mx/conavi/documentos/reglas-de-operacion-del-programa-nacional-de-reconstruccion-2021
                                        " target="blank_"><p style={{ fontWeight: "bold" }} class="red"><i class="far fa-arrow-alt-circle-right"></i><em> Reglas de Operación del Programa Nacional de Reconstrucción 2021</em></p></a>
							</div>*/}
							<div>
								<p class="gold font-xl">PROGRAMA DE VIVIENDA SOCIAL </p>
								<img src={PVS} class="img-responsive  bottom-buffer" />
								<p><strong>Objetivo General:</strong> Disminuir la carencia de una vivienda adecuada de la población en situación de vulnerabilidad por condiciones sociodemográficas, por riesgo o por precariedad de su vivienda.</p>
								<a href="https://www.gob.mx/conavi/documentos/reglas-de-operacion-del-programa-de-vivienda-social-2021
                                        " target="blank_"><p style={{ fontWeight: "bold" }} class="red"> <i class="far fa-arrow-alt-circle-right"></i><em> Reglas de Operación del Programa de Vivienda Social 2021</em></p></a>
							</div>
							<div class="top-buffer">
								<button class="btn btn-primary bottom-buffer" type="button" onClick={(e) => scrollFocus("tramite")}>
									<span class="fas fa-tasks"></span> Realizar un trámite
								</button>
							</div>
						</div>


						<div class="col-md-1">

						</div>

						<div class="row" style={{ padding: 20 }}>

							<div id="bodyVideo" class="col-md-4 bottom-buffer">
								<h3 style={{ textAlign: "justify" }}>¿Quiénes somos?</h3>
								<hr class="red bottom-buffer"></hr>
								<video style={{ width: "100%", marginTop: 20 }} playsinline="playsinline" autoplay="autoplay" controls>
									<source src="https://sistemaintegral.conavi.gob.mx:81/dac_2022_/Somos_Conavi_2023.mp4" type="video/mp4" />
								</video>
							</div>
							<div class="col-md-1">

							</div>
							<div id="bodyVideo" class="col-md-4 bottom-buffer">
								<h3 style={{ textAlign: "justify" }}>¡No te dejes engañar!</h3>
								<hr class="red bottom-buffer"></hr>
								<video style={{ width: "100%", marginTop: 20 }} playsinline="playsinline" controls>
									<source src="https://sistemaintegral.conavi.gob.mx:81/dac_2022_/VIDEO.mp4" type="video/mp4" />
								</video>
							</div>

							<div class="col-md-1">

							</div>

							<div class="div-color col-md-4 bottom-buffer img-back">
								<p class="text-center"><i style={{ fontSize: 30 }} class="fas fa-question-circle" /></p>
								<p style={{ fontSize: 25 }} class="text-center">¡Resuelve tus dudas!</p>
								<p style={{ fontSize: 20 }} class="text-center">Consulta nuestras preguntas frecuentes <strong class="enlace"><Link class="nav-link" to="/Frecuentes">aquí</Link></strong> </p>

							</div>

							<div id="cdyo_" class="hidden col-md-1 img-back">

							</div>

							<div>
								<a href="../dac_2022_/Carta DyO.pdf" target="blank_">
									<div id='cdyo' class='hidden enlace hover03 column col-md-4 bottom-buffer' >
										<div>
											<figure>
												<img class='element-gal img-responsive text-center' src={cdyo} />
											</figure>
										</div>
									</div></a>
							</div>

						
							<div class="col-md-4 ">

								<h3 style={{ textAlign: "justify" }}>¿Te podemos ayudar en algo más?</h3>
								<hr class="red bottom-buffer"></hr>
								<div id="tramite" class="div-color img-back" style={{ backgroundColor: "#38000b" }}>

									<div class="dropdown-at-md dropbtn-at-md">
										<p style={{ color: "#fff" }}>Para continuar, por favor <strong>ingresa tu CURP.</strong></p>
										<div class="row ">
											<div class="col-md-8">
												<input maxLength="18" id='txtCurp' type="text" class="form-control" style={{ textTransform: "uppercase" }}></input>
											</div>
											<div class="col-md-4">
												<button onClick={(e) => buscarBeneficiario(e.target.id)} id="btnBcurp" class="btn-primary gold-btn" ><i style={{ paddingLeft: 5, paddingRight: 5 }} class="btnBcurp fas fa-search" ></i></button>
											</div>
										</div>

										<div id="response_cnv" class="dropdown-content-at-md">
											<div class="option-dropdown-at-md">
												<div><p class="p-center"><i style={{ fontSize: 40 }} class="icon-cnv"></i></p></div>
												<p class="res-registo"><p class="res"></p></p>
												<a href="../dac_2022_/Carta DyO.pdf" class="enlace" target="_blank"><p class="res-derechos"><strong><i class="fas fa-book-open" /> CONOCER MIS DERECHOS Y OBLIGACIONES</strong></p></a>


												<p><Link to="/Informacion" class="enlace"><strong><i style={{ marginRight: 10 }} class="fas fa-question-circle"></i>TENGO DUDAS</strong></Link></p>
												<p><Link to="/Queja" class="enlace"><strong><i style={{ marginRight: 10 }} class="fas fa-exclamation-circle"></i>DESEO PRESENTAR UNA QUEJA O DENUNCIA</strong></Link></p>
												<p class="res-solicitar"><Link to="/Vivienda" class="enlace"><strong><i style={{ marginRight: 10 }} class="fas fa-home" />QUIERO OBTENER UN APOYO DE VIVIENDA</strong></Link></p>
											</div>
										</div>



									</div>
									<div class="top-buffer">
										<p id="b-ine" style={{ cursor: "pointer" }}><strong><span style={{ color: "#fff" }}><i class="fas fa-info-circle"></i> ¿Dónde encuentro la CURP en mi IFE/INE?</span></strong></p>
										<p id="b-curp" style={{ cursor: "pointer" }}><strong><span style={{ color: "#fff" }}><i class="fas fa-question-circle"></i> Click aquí si no sabes cuál es tu CURP</span></strong></p>
									</div>



								</div>
							</div>

						</div>
					</div>





					<h2>Contraloría Social</h2>
					<hr class="red bottom-buffer"></hr>
					<h4 style={{ textAlign: "justify" }} class="bottom-buffer-x15">Mecanismo de participación ciudadana para realizar acciones de vigilancia y evaluación, con el objetivo de contribuir a que la gestión gubernamental y el manejo de los recursos públicos se realicen en un marco de transparencia, eficacia, legalidad y honradez.</h4>
					<p>¿Quieres conocer más sobre la participación de las personas beneficiarias en la vigilancia y monitoreo de la correcta ejecución de nuestros Programas?</p>

					<a href="https://sistemaintegral.conavi.gob.mx:81/cs/#/ContraloriaSocial">
						<button class="btn btn-primary bottom-buffer" type="button">
							<span class="fas fa-share"></span> Da clic aquí
						</button>
					</a>


					<h2>Medios de contacto</h2>
					<hr class="red bottom-buffer"></hr>

					<p class="bottom-buffer" style={{ textAlign: "justify" }}>La Conavi pone a tu disposición los siguientes medios de comunicación:</p>
					<div class="row">

						<div class="col-md-4 bottom-buffer-x15 dropdown-at dropbtn-at">
							<div class="card">
								<i style={{ fontSize: 50 }} class="fab fa-whatsapp red-ic"></i>
								<p class="card-tittle red">WhatsApp “Conavi te Atiende”</p>
								<p class="p-center">55-59-64-17-13</p>
								<div class="dropdown-content-at">
									<div class="option-dropdown-at">
										<i class="fas fa-info-circle"></i>
										<p>Atiende a personas beneficiarias de los Programas a cargo de la Conavi.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 bottom-buffer-x15 dropdown-at dropbtn-at">
							<div class="card">
								<i style={{ fontSize: 50 }} class="fas fa-phone red-ic"></i>
								<p class="card-tittle red">Atención telefónica</p>
								<p class="p-center">55-91-38-99-91 opción 1</p>
								<div class="dropdown-content-at">
									<div class="option-dropdown-at">
										<i class="fas fa-info-circle"></i>
										<p>Orienta y da seguimiento a las peticiones ciudadanas en materia de vivienda.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-4 bottom-buffer-x15 dropdown-at dropbtn-at">
							<div class="card">
								<i style={{ fontSize: 50 }} class="fab fa-whatsapp red-ic"></i>
								<p class="card-tittle red">WhatsApp “Conavi Denuncia”</p>
								<p class="p-center">55-85-63-49-24</p>
								<div class="dropdown-content-at">
									<div class="option-dropdown-at">
										<i class="fas fa-info-circle"></i>
										<p>Recibe información ciudadana sobre presuntos fraudes en el marco de la implementación de los Programas a cargo de la Conavi.</p>
									</div>
								</div>

							</div>
						</div>

					</div>

					<div class="row">

						<div class="col-md-6 bottom-buffer-x15 dropdown-at dropbtn-at">
							<div class="card">

								<i style={{ fontSize: 50 }} class="fas fa-at red-ic"></i>
								<p class="card-tittle red">Correo electrónico</p>
								<p class="p-center">atencionciudadana @conavi.gob.mx</p>
								<div class="dropdown-content-at">
									<div class="option-dropdown-at">
										<i class="fas fa-info-circle"></i>
										<p>Orienta y da seguimiento a las peticiones ciudadanas en materia de vivienda.</p>
									</div>
								</div>
							</div>
						</div>

						<div class="col-md-6 bottom-buffer dropdown-at dropbtn-at">
							<div class="card">

								<i style={{ fontSize: 50 }} class="icon-cnv red-ic"></i>
								<p class="card-tittle red">Síguenos en nuestras redes sociales</p>

								<a style={{ fontSize: 30, paddingLeft: 5, paddingRight: 5 }} href="https://www.facebook.com/ConaviMexico/" target="_blank"><span class="fab fa-facebook red-ic"> </span></a>
								<a style={{ fontSize: 30, paddingLeft: 5, paddingRight: 5 }} href="https://twitter.com/Conavi_mx" target="_blank"><span class="fab fa-twitter red-ic"> </span></a>
								<a style={{ fontSize: 30, paddingLeft: 5, paddingRight: 5 }} href="https://www.instagram.com/conavi_mx/" target="_blank"><span class="fab fa-instagram red-ic"> </span></a>
								<a style={{ fontSize: 30, paddingLeft: 5, paddingRight: 5 }} href="https://www.youtube.com/channel/UCpjQEZzEE0ekH19IEM-KQCQ" target="_blank"><span class="fab fa-youtube red-ic"> </span></a>



							</div>
						</div>


					</div>

				</div>

				<div class="colofon red-text text-center">
					<div class="row row-fixed">
						<div class="col-lg-12 my-auto showcase-text">
							<p class="lead mb-0 p-center">
								Avenida H. Escuela Naval Militar, número 669, piso 2 y 3, Colonia Presidentes Ejidales 1ra. Sección, Alcaldía Coyoacán, C.P. 04470, Ciudad de México.
								<br></br>Teléfono: 55.91.38.99.91<br></br>Opción 1<br></br>atencionciudadana@conavi.gob.mx
							</p>
						</div>
					</div>
				</div>

			</header>
		</div>
	);
}

export default Inicio;