import $, { each } from 'jquery';
import Accesibilidad from '../components/Accesibilidad'
import GaleriaHeader from '../components/GaleriaHeader'
import Alerta from '../components/Alerta';
import Alerta_ from '../assets/js/Alerta';
import React, { useState, useEffect } from 'react';
import '../assets/css/content.css';
import '../assets/css/navbar.css';

const out = () => {
    localStorage.clear();
    window.location.href = "/dac_2022_/#/Admin";
}

const back = () => {
    localStorage.removeItem("tipo_solicitud");
    localStorage.removeItem("id_solicitud");
    window.location.href = "/dac_2022_/#/Panel";
}


function isLoged() {
    const isLogged = localStorage.getItem('credenciales_dac');
    if (!isLogged) {
        out();
    } else {
        const tipo = localStorage.getItem("tipo_solicitud");
        if (!tipo) {
            back();
        } else {
            llenarSolicitud(localStorage.getItem("tipo_solicitud"), localStorage.getItem("id_solicitud"));
        }
    }
}

function llenarSolicitud(tipo, id) {
    let identi = ""

    switch (tipo) {
        case "SOLICITUD DE VIVIENDA":
            identi = "v_";
            $("#dvivienda").removeClass("hidden");
            break;
        case "QUEJA O DENUNCIA":
            identi = "q_";
            $("#dquejas").removeClass("hidden");
            break;
        case "SOLICITUD DE INFORMACIÓN":
            identi = "i_";
            $("#sinfo").removeClass("hidden");
            break;
    }

    $.getJSON("https://sistemaintegral.conavi.gob.mx:81/api/buscarSolcitud/" + id + "/" + tipo, function (respuesta) {
        console.log(respuesta)
        $.each(respuesta, function (key, val) {
            $.each(val, function (id, valor) {
                let _attr = valor;
                if (valor === "") { $("#_" + id).text(" ") } else { $("#" + identi + id).text(_attr.toUpperCase()) }

            });
        });
    });


}

function Solicitud(props) {

    useEffect(() => {
        isLoged();
    });
    return (
        <div className="Inicio">
            <header>

                <Accesibilidad />
                <Alerta />
                <GaleriaHeader />

                <div class="container">


                    <div id="muestra-info">
                        <div class="top-buffer">

                            <ol id="breadcrumb" class="breadcrumb">
                                <li>
                                    <a href="">
                                        <i class="icon icon-home">
                                        </i>
                                    </a>
                                </li>
                                <li class="">
                                    <a class="nav-link" href="#/Panel">Panel de administrador</a>
                                </li>
                                <li class="active">
                                    <a class="nav-link" href="">Visor de solicitudes</a>
                                </li>
                            </ol>
                            <div class="div-vivienda-form hidden" id="dvivienda">
                                {/*Solicitud de vivienda*/}
                                <div class="features-icons-icon d-flex text-center"><i class="fas fa-home gold" style={{ fontSize: 80 }}></i></div>
                                <h2 id="titulo_principal" class="text-center">Solicitud de vivienda</h2>


                                <div class="bottom-buffer-x2 top-buffer">
                                    <div class="row">
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_nombre">
                                            <span>Nombre (s)</span>
                                            <br></br>
                                            <strong id="v_nombre">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_apellido1">
                                            <span>Primer apellido</span>
                                            <br></br>
                                            <strong id="v_apellido1">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_apellido2">
                                            <span>Segundo apellido</span>
                                            <br></br>
                                            <strong id="v_apellido2">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">

                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_telefono">
                                            <span>Telefono de contacto (1)</span>
                                            <br></br>
                                            <strong id="v_telefono">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_telefono2">
                                            <span>Telefono de contacto (2)</span>
                                            <br></br>
                                            <strong id="v_telefono2">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_correo">
                                            <span>Correo Electrónico
                                            </span>
                                            <br></br>
                                            <strong id="v_correo">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_individual">
                                            <span>Ingreso mensual individual</span>
                                            <br></br>
                                            <strong id="v_individual">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_familiar">
                                            <span>Ingreso mensual familiar</span>
                                            <br></br>
                                            <strong id="v_familiar">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_remesas">
                                            <span>Ingreso por remesas</span>
                                            <br></br>

                                            <strong id="v_remesas">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_ahorro">
                                            <span>¿Cuenta con ahorro previo?</span>
                                            <br></br>
                                            <strong id="v_ahorro">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_terreno">
                                            <span>¿Cuenta con terreno propio?</span>
                                            <br></br>
                                            <strong id="v_terreno">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_derechohabiencia">
                                            <span>¿Eres derechohabiente?</span>
                                            <br></br>
                                            <strong id="v_derechohabiencia">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_indigena">
                                            <span>¿Perteneces a algún grupo indígena?</span>
                                            <br></br>
                                            <strong id="v_indigena">

                                            </strong>
                                        </p>

                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_jefa">
                                            <span>Eres jefa de hogar?</span>
                                            <br></br>
                                            <strong id="v_jefa">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_desplazado">
                                            <span>¿Eres desplazado por violencia?</span>
                                            <br></br>
                                            <strong id="v_desplazado">

                                            </strong>
                                        </p>
                                    </div>
                                    <hr></hr>
                                    <div class="row">
                                        <p class="col-md-6 p-center bottom-buffer-x15" id="p_discapacidad">
                                            <span>¿Tiene alguna discapacidad?</span>
                                            <br></br>

                                            <strong id="v_discapacidad">

                                            </strong>
                                        </p>
                                        <p class="col-md-6 p-center bottom-buffer-x15" id="p_migrante">
                                            <span>¿Es migrante mexicano en el extranjero ó en retorno?</span>
                                            <br></br>
                                            <strong id="v_migrante">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">

                                        <p class="col-md-6 p-center bottom-buffer-x15" id="p_fenomeno">
                                            <span>¿Su vivienda fue afectada por algún fenómeno natural perturbador?</span>
                                            <br></br>

                                            <strong id="v_fenomeno">

                                            </strong>
                                        </p>
                                        <p class="col-md-6 p-center bottom-buffer-x15" id="p_txtfenomeno">
                                            <span>¿Cuál?</span>
                                            <br></br>
                                            <strong id="v_txtfenomeno">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-6 p-center bottom-buffer-x15" id="p_Especifiquefenomeno">
                                            <span>Especifique</span>
                                            <br></br>
                                            <strong id="v_Especifiquefenomeno">

                                            </strong>
                                        </p>
                                        <p class="col-md-6 p-center bottom-buffer-x15" id="p_riesgos">
                                            <span>¿Existe algún tipo de riesgos en la zona donde habita?</span>
                                            <br></br>
                                            <strong id="v_riesgos">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-6 p-center bottom-buffer-x15" id="p_programa">
                                            <span>Programa perfilado</span>
                                            <br></br>
                                            <strong id="v_programa">

                                            </strong>
                                        </p>
                                    </div>
                                    <hr></hr>
                                    <div class="row">

                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_entidad">
                                            <span>Entidad de donde nos contactas
                                            </span>
                                            <br></br>
                                            <strong id="v_entidad">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_municipio">
                                            <span>Municipio de donde nos contactas
                                            </span>
                                            <br></br>
                                            <strong id="v_municipio">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_localidad">
                                            <span>Localidad de donde nos contactas</span>
                                            <br></br>
                                            <strong id="v_localidad">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_idVialidad">
                                            <span>Tipo de Vialidad</span>
                                            <br></br>
                                            <strong id="v_idVialidad">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_numExt">
                                            <span>Número Exterior</span>
                                            <br></br>
                                            <strong id="v_numExt">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_letExt">
                                            <span>Letra Exterior</span>
                                            <br></br>
                                            <strong id="v_letExt">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_numExtAnt">
                                            <span>Número Exterior Anterior</span>
                                            <br></br>
                                            <strong id="v_numExtAnt">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_numInt">
                                            <span>Número Interior</span>
                                            <br></br>
                                            <strong id="v_numInt">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_letInt">
                                            <span>Letra Interior</span>
                                            <br></br>
                                            <strong id="v_letInt">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_cp">
                                            <span>Código Postal</span>
                                            <br></br>
                                            <strong id="v_cp">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_idAsentamiento">
                                            <span>Tipo de Asentamiento</span>
                                            <br></br>
                                            <strong id="v_idAsentamiento">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_asentamiento">
                                            <span>Nombre del Asentamiento</span>
                                            <br></br>
                                            <strong id="v_asentamiento">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_idVialidad_1">
                                            <span>Vialidad
                                            </span>
                                            <br></br>
                                            <strong id="v_idVialidad_1">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_vialidad_1">
                                            <span>Nombre entre vialidad
                                            </span>
                                            <br></br>
                                            <strong id="v_vialidad_1">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_idVialidad_2">
                                            <span>Vialidad</span>
                                            <br></br>
                                            <strong id="v_idVialidad_2">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_vialidad_2">
                                            <span>Nombre entre vialidad</span>
                                            <br></br>
                                            <strong id="v_vialidad_2">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_idPosterior">
                                            <span>Vialidad Posterior</span>
                                            <br></br>
                                            <strong id="v_idPosterior">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_posterior">
                                            <span>Nombre Vialidad Posterior</span>
                                            <br></br>
                                            <strong id="v_posterior">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-6 p-center bottom-buffer-x15" id="p_referencia">
                                            <span>REFERENCIA DEL DOMICILIO</span>
                                            <br></br>
                                            <strong id="v_referencia">

                                            </strong>
                                        </p>
                                        <p class="col-md-6 p-center bottom-buffer-x15" id="p_descripcion">
                                            <span>DESCRIPCIÓN DE LA SOLICITUD</span>
                                            <br></br>

                                            <strong id="v_descripcion">

                                            </strong>
                                        </p>
                                    </div>

                                </div>
                            </div>

                            {/*Quejas y denuncias*/}

                            <div class="div_queja_form hidden" id="dquejas">

                                <div class="features-icons-icon d-flex text-center"><i class="fas fa-bullhorn gold" style={{ fontSize: 80 }}></i></div>
                                <h2 id="titulo_principal" class="text-center">Queja o denuncia</h2>
                                <div class="bottom-buffer-x2 top-buffer">
                                    <div class="row">
                                        <div class="row">
                                            <p class="col-md-12 p-center bottom-buffer-x15" >
                                                <span>Curp</span>
                                                <br></br>
                                                <strong id="q_curp">

                                                </strong>
                                            </p>
                                        </div>
                                        <div class="row">

                                            <p class="col-md-4 p-center bottom-buffer-x15" >
                                                <span>Nombre (s)</span>
                                                <br></br>
                                                <strong id="q_nombre">

                                                </strong>
                                            </p>
                                            <p class="col-md-4 p-center bottom-buffer-x15" >
                                                <span>Primer apellido</span>
                                                <br></br>
                                                <strong id="q_apellido1">

                                                </strong>
                                            </p>
                                            <p class="col-md-4 p-center bottom-buffer-x15" >
                                                <span>Segundo apellido</span>
                                                <br></br>
                                                <strong id="q_apellido2">

                                                </strong>
                                            </p>
                                        </div>
                                        <div class="row">
                                            <p class="col-md-4 p-center bottom-buffer-x15" >
                                                <span>Telefono de contacto (1)</span>
                                                <br></br>
                                                <strong id="q_telefono">

                                                </strong>
                                            </p>
                                            <p class="col-md-4 p-center bottom-buffer-x15" >
                                                <span>Telefono de contacto (2)</span>
                                                <br></br>
                                                <strong id="q_telefono2">

                                                </strong>
                                            </p>
                                            <p class="col-md-4 p-center bottom-buffer-x15" >
                                                <span>Correo Electrónico
                                                </span>
                                                <br></br>
                                                <strong id="q_correo">

                                                </strong>
                                            </p>
                                        </div>
                                        <div class="row">
                                            <p class="col-md-4 p-center bottom-buffer-x15" >
                                                <span>Si eres integrante de algún Comité de Contraloría Social, especifica cuál</span>
                                                <br></br>
                                                <strong id="q_comite">

                                                </strong>
                                            </p>
                                            <p class="col-md-4 p-center bottom-buffer-x15" >
                                                <span>¿Cuándo ocurrieron los hechos?</span>
                                                <br></br>
                                                <strong id="q_cuando">

                                                </strong>
                                            </p>
                                            <p class="col-md-4 p-center bottom-buffer-x15" >
                                                <span>Entidad donde ocurrieron los hechos</span>
                                                <br></br>
                                                <strong id="q_entidad">

                                                </strong>
                                            </p>
                                        </div>
                                        <div class="row">
                                            <p class="col-md-6 p-center bottom-buffer-x15" >
                                                <span>
                                                    Municipio donde ocurrieron los hechos</span>
                                                <br></br>
                                                <strong id="q_municipio">

                                                </strong>
                                            </p>
                                            <p class="col-md-6 p-center bottom-buffer-x15" >
                                                <span>Localidad donde ocurrieron los hechos</span>
                                                <br></br>
                                                <strong id="q_localidad">

                                                </strong>
                                            </p>
                                        </div>
                                        <div class="row">
                                            <p class="col-md-12 p-center bottom-buffer-x15" >
                                                <span>Narra el motivo de su denuncia</span>
                                                <br></br>
                                                <strong id="q_narrativa">

                                                </strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*Solicitud de información*/}

                            <div class="div-informacion-form hidden" id="sinfo">
                                <div class="features-icons-icon d-flex text-center"><i class="fas fa-info-circle gold" style={{ fontSize: 80 }}></i></div>
                                <h2 id="titulo_principal" class="text-center">Solicitud de información</h2>
                                <div class="bottom-buffer-x2 top-buffer">
                                    <div class="row">
                                        <p class="col-md-12 p-center bottom-buffer-x15" id="p_curp">
                                            <span>Curp</span>
                                            <br></br>
                                            <strong id="i_curp">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">

                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_nombre">
                                            <span>Nombre (s)</span>
                                            <br></br>
                                            <strong id="i_nombre">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_apellido1">
                                            <span>Primer apellido</span>
                                            <br></br>
                                            <strong id="i_apellido1">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_apellido2">
                                            <span>Segundo apellido</span>
                                            <br></br>
                                            <strong id="i_apellido2">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_telefono">
                                            <span>Telefono de contacto (1)</span>
                                            <br></br>
                                            <strong id="i_telefono">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_telefono2">
                                            <span>Telefono de contacto (2)</span>
                                            <br></br>
                                            <strong id="i_telefono2">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_correo">
                                            <span>Correo Electrónico
                                            </span>
                                            <br></br>
                                            <strong id="i_correo">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-12 p-center bottom-buffer-x15" id="p_comite">
                                            <span>Si eres integrante de algún Comité de Contraloría Social, especifica cuál</span>
                                            <br></br>
                                            <strong id="i_comite">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_entidad">
                                            <span>Entidad de donde nos contactas</span>
                                            <br></br>
                                            <strong id="i_entidad">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_municipio">
                                            <span>
                                                Municipio de donde nos contactas</span>
                                            <br></br>
                                            <strong id="i_municipio">

                                            </strong>
                                        </p>
                                        <p class="col-md-4 p-center bottom-buffer-x15" id="p_localidad">
                                            <span>Localidad de donde nos contactas</span>
                                            <br></br>
                                            <strong id="i_localidad">

                                            </strong>
                                        </p>
                                    </div>
                                    <div class="row">

                                        <p class="col-md-12 p-center bottom-buffer-x15" id="p_solicitud">
                                            <span>Narre la solicitud de información que desea realizar</span>
                                            <br></br>
                                            <strong id="i_solicitud">

                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <button onClick={back}
                                class="btn-sm btn-default" type="button" id="atras_general">
                                <span class="fas fa-chevron-left"></span> Atrás
                            </button>
                        </div>
                    </div>
                </div>
                <div class="colofon red-text text-center">
                    <div class="row row-fixed">
                        <div class="col-lg-12 my-auto showcase-text">
                            <p class="lead mb-0 p-center">
                                Av. H. Escuela Naval Militar, Coapa, Presidentes Ejidales 1ª Sección, Ciudad de México. C.P. 04470<br></br>Teléfono: 55.91.38.99.91<br></br>Opción 1<br></br>atencionciudadana@conavi.gob.mx
                            </p>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );

}

export default Solicitud;
