import $, { each } from 'jquery';
import '../../assets/css/scroll.css';
import '../../assets/css/modal.css';
import '../../assets/css/modal_.css';
import '../../assets/css/content.css';

import ine from '../../assets/img/INE_V.jpg';
function cerrar(){
	$("#modal").css("display", "none");
	$("#content-rec").css("display", "none");
}
export default function ModalRecurso() {


	return (
		
				<div class="modal" id="content-rec" >
					<div class="modal-dialog">
						<div class="modal-content">
							<div class="modal-header">
								<h4 class="modal_-title">¡ATENCION!</h4>
							</div>
							<div class="modal-body">
								<p>Se informa que el recurso presupuestal del ejercicio fiscal 2024 se encuentra comprometido, por lo que no existen recursos para continuar con el otorgamiento de subsidios.</p>
								<p>Las solicitudes registradas serán analizadas una vez que se definan los programas que operará la Conavi en ejercicios fiscales subsecuentes.</p>
							</div>
							<div class="modal-footer">
								<button type="button" class="btn btn-default" onClick={cerrar}>Cerrar</button>

							</div>
						</div>
					</div>
				</div>
		

	);
}