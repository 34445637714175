import $ from 'jquery';
import Accesibilidad from '../components/Accesibilidad'
import GaleriaHeader from '../components/GaleriaHeader'
import React, { useState, useEffect } from 'react';
import Alerta from '../components/Alerta';
import Alerta_ from '../assets/js/Alerta.js';
import Video from '../assets/vid/VIDEO.mp4';
import PNR from '../assets/img/pnr.jpg';
import PVS from '../assets/img/pvs.jpg';


import Modalcurp from '../components/modal/Modalcurp'
import Modaline from '../components/modal/Modaline'
import '../assets/css/scroll.css';
import '../assets/css/navbar.css';
import '../assets/css/content.css';
import '../assets/css/emergente.css';
import '../assets/css/datepicker.css';
import '../assets/js/modales.js';



import { Link, withRouter } from "react-router-dom";

const scroll = (id) => {
	let el = document.getElementById(id);
	el.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
}

const scrollFocus = (id) => {
	let el = document.getElementById(id);
	el.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
	$("#" + id).addClass("shine");
}

const load = () => {


	isLoged();

	/*    var user = JSON.parse(localStorage.getItem('credenciales'));
		$(".ic-modulos").remove();
		$.each(user, function (key, val) {
			$("#usr").text(val.nombre);
		});
	*/

}

function isLoged() {
	const isLogged = localStorage.getItem('credenciales_dac');
	if (!isLogged) {
		out();
	} else {

	}
}

const out = () => {
	localStorage.clear();
	window.location.href = "/dac_2022_/#/Admin";
}

const buscarBeneficiario = (button) => {


	$(".dropdown-content-at-md").css("display", "none");
	if ($("#txtCurp").val().length == 18) {

		$(".btnBcurp").addClass("fa fa-spin fa-spinner");
		$("#btnBcurp").prop('disabled', true);

		$.getJSON("https://sistemaintegral.conavi.gob.mx:81/api/padron_historicoAdmin/" + $("#txtCurp").val(), function (respuesta) {
			var res = "";
			var nombre = "";
			var programa = "";
			var modalidad = "";
			var ejercicio_fiscal = "";
			var estatus = "";
			$(".res-registo").remove(".res")
			try {
				$.each(respuesta, function (key, val) {
					if (val.NOMBRE == null) {
						nombre = val.CURP;
					} else {
						nombre = val.NOMBRE;
					}

					programa = val.PROGRAMA;
					modalidad = val.MODALIDAD;
					ejercicio_fiscal = val.EJERCICIO_FISCAL;
					estatus = val.ESTATUS;
					res = res + "<p class='res'>El beneficiario/a <strong>" + nombre.toUpperCase() + "</strong> fue acreedor a la modalidad <strong>" + modalidad.toUpperCase() + "</strong> en el programa <strong>" + programa.toUpperCase() + "</strong> en el año <strong>" + ejercicio_fiscal + "</strong> con status <strong>" + estatus.toUpperCase() + "</strong></p>"
				});

				if (nombre == "") {
					res = "<p class='res'>Este/a beneficiario/a <strong>no se encuentra</strong> en el histórico.</p>"
					$(".res-registo").find("p").remove(".res");
					$(".res-registo").append(res);
					$(".res-derechos").css("display", "none");
					$(".res-solicitar").css("display", "block");
					$(".dropdown-content-at-md").css("display", "block");

				} else {

					$(".res-registo").find("p").remove(".res");
					$(".res-registo").append(res);
					$(".res-derechos").css("display", "block");
					$(".res-solicitar").css("display", "none");
					$(".dropdown-content-at-md").css("display", "block");

				}
				$(".btnBcurp").removeClass("fa fa-spin fa-spinner");
				$("#btnBcurp").prop('disabled', false);
				scroll("response_cnv");
			} catch (err) {

			}






		});

	} else {
		new Alerta_("La CURP debe contener 18 dígitos", "alertaOp alert alert-danger alert-dismissible");
	}

}

function Historico(props) {
	const [fInicial, setfInicial] = useState(new Date());
	const [fFinal, setfFinal] = useState(new Date());

	useEffect(() => {
		load();
	});
	return (
		<div className="Inicio">
			<header>


				<Accesibilidad />
				<Alerta />
				<GaleriaHeader />


				<div id="cont" class="container">


					<ol class="breadcrumb">
						<li><a href=""><i class="icon icon-home"></i></a></li>
						<li class=""><Link class="nav-link" to="/Panel">Panel de administrador</Link></li>
						<li class="active">Histórico</li>
					</ol>



					<h2>Consulta histórica de personas beneficiarias</h2>
					<hr class="red bottom-buffer"></hr>

					<div style={{ marginLeft: 0, marginRight: 0 }} class="row" >

						<div id="tramite" class="col-md-6 div-color img-back" style={{ backgroundColor: "#38000b" }}>

							<div class="dropdown-at-md dropbtn-at-md">
								<p class="bottom-buffer" style={{ color: "#fff" }}>Para continuar, por favor <strong>ingresa la CURP del beneficiario/a a consultar.</strong></p>
								<div class="row ">
									<div class="col-md-10">
										<input maxLength="18" id='txtCurp' type="text" class="form-control" style={{ textTransform: "uppercase" }}></input>
									</div>
									<div class="col-md-2">
										<button onClick={(e) => buscarBeneficiario(e.target.id)} id="btnBcurp" class="btn-primary gold-btn" ><i style={{ paddingLeft: 5, paddingRight: 5 }} class="btnBcurp fas fa-search" ></i></button>
									</div>
								</div>

								<div id="response_cnv" class="dropdown-content-at-md">
									<div class="option-dropdown-at-md">
										<div><p class="p-center"><i style={{ fontSize: 40 }} class="icon-cnv"></i></p></div>
										<p class="res-registo"><p class="res"></p></p>
									</div>
								</div>



							</div>




						</div>

					</div>


				</div>

				<div class="colofon red-text text-center">
					<div class="row row-fixed">
						<div class="col-lg-12 my-auto showcase-text">
							<p class="lead mb-0 p-center">
								Avenida H. Escuela Naval Militar, número 669, piso 2 y 3, Colonia Presidentes Ejidales 1ra. Sección, Alcaldía Coyoacán, C.P. 04470, Ciudad de México.
								<br></br>Teléfono: 55.91.38.99.91<br></br>Opción 1<br></br>atencionciudadana@conavi.gob.mx
							</p>
						</div>
					</div>
				</div>

			</header>
		</div>
	);
}

export default Historico;