import $ from 'jquery';
import Alerta_ from './Alerta.js';

$(document).ready(function () {

    $("#b-curp").click(function () {
        $("#modal").css("display", "block");
        $("#content-c").css("display", "block");
    });

    function cerrar_c() {
        $('#nombre').val("");
        $('#apellido1').val("");
        $('#apellido2').val("");

        $('#cmbDia option[value=0]').prop('selected', true);
        $('#cmbMes option[value=0]').prop('selected', true);
        $('#cmbAnio option[value=0]').prop('selected', true);
        $('#cmbSexo option[value=0]').prop('selected', true);
        $('#cmbEstado option[value=0]').prop('selected', true);

        $("#modal").css("display", "none");
        $("#content-i").css("display", "none");
    }

    $("#btnCerrar-c").click(function () {

        cerrar_c();

    });

    $("#btnBuscar-c").click(function () {

        var nb = $('#nombre').val();
        var pa = $('#apellido1').val();
        var sa = $('#apellido2').val();
        var dd = $('#cmbDia').children("option:selected").val();
        var mm = $('#cmbMes').children("option:selected").val();
        var aa = $('#cmbAnio').children("option:selected").val();
        var sx = $('#cmbSexo').children("option:selected").val();
        var ef = $('#cmbEstado').children("option:selected").val();

        if (nb == "" || pa == "" || sa == "" || dd == "0" || mm == "0" || aa == "0" || sx == "0" || ef == "0") {
            new Alerta_("Faltan campos por capturar", "alertaOp alert alert-warning alert-dismissible")
        } else {

            $.get("https://sistemaintegral.conavi.gob.mx:81/api/renapoDatos/" + nb.toUpperCase() + "/" + pa.toUpperCase() + "/" + sa.toUpperCase() + "/" + dd + "/" + mm + "/" + aa + "/" + sx + "/" + ef, function (respuesta) {
                if (respuesta.statusOper === "EXITOSO") {
                    $('#txtCurp').val(respuesta.curp);
                    cerrar_c();

                } else {
                    new Alerta_("La CURP no se encuentra en la base de datos", "alertaOp alert alert-danger alert-dismissible")

                }
            });
        }

    });





    /*INE*/
    $("#b-ine").click(function () {
        $("#modal").css("display", "block");
        $("#content-i").css("display", "block");
    });

    $("#btnCerrar-i").click(function () {

        $("#modal").css("display", "none");
        $("#content-i").css("display", "none");

    });
});